import React, { useEffect, useState } from 'react';
import constants from '../../config/constants';
import { loginService } from '../../services/auth.service';
import { useNavigate } from 'react-router';
import setAuthToken from '../../utils/setAuthToken';
import { updateUserInfo } from '../../store/reducer/authSlice';
import { useDispatch } from 'react-redux';
import { FaDeleteLeft } from 'react-icons/fa6';

const LoginWithPINPage = () => {
  const [lstPIN, setLstPIN] = useState([null, null, null, null]);
  const [username, setUsername] = useState('');
  const [fillIndex, setFillIndex] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickNumber = (n) => {
    setLstPIN((prev) => {
      let isFirst = true;
      prev = prev.map((item, i) => {
        if (!item && isFirst && item !== 0) {
          isFirst = false;
          return n;
        }
        return item;
      });

      return prev;
    });
  };

  const handleDeleteNumber = () => {
    let pinDeleted = JSON.parse(JSON.stringify(lstPIN));
    for (let i = pinDeleted.length - 1; i >= 0; i--) {
      if (pinDeleted[i] || pinDeleted[i] === 0) {
        pinDeleted[i] = null;
        break;
      }
    }
    setLstPIN(pinDeleted);
  };

  const login = async (PIN) => {
    const res = await loginService({
      username: username,
      passcode: PIN,
    });
    if (!res.success) {
      alert(res.message);
      setLstPIN([null, null, null, null]);
    } else {
      localStorage.setItem(constants.TOKEN_NAME, res.data.token);
      setAuthToken(res.data.token);
      dispatch(updateUserInfo(res.data.user));
      navigate('/');
    }
  };

  useEffect(() => {
    let PIN = '';
    lstPIN.forEach((n) => {
      if (n || n === 0) {
        PIN = PIN + n.toString();
      }
    });
    if (PIN.length === 4) {
      login(PIN);
    }
    setFillIndex(PIN.length);
  }, [lstPIN]);

  useEffect(() => {
    setUsername(localStorage.getItem(constants.USERNAME));
  }, []);

  return (
    <div id='info-page'>
      <div className='login-input-group'>
        <p>Nhập mã PIN của {username}</p>
        <div className='pin-display'>
          {lstPIN.map((n, i) => {
            return (
              <div
                className={`pin-item ${fillIndex === i ? 'fill' : ''}`}
                key={i}
              >
                {n || n === 0 ? n : ''}
              </div>
            );
          })}
        </div>
        <div className='pin-number'>
          <div className='number-item' onClick={() => handleClickNumber(1)}>
            1
          </div>
          <div className='number-item' onClick={() => handleClickNumber(2)}>
            2
          </div>
          <div className='number-item' onClick={() => handleClickNumber(3)}>
            3
          </div>
          <div className='number-item' onClick={() => handleClickNumber(4)}>
            4
          </div>
          <div className='number-item' onClick={() => handleClickNumber(5)}>
            5
          </div>
          <div className='number-item' onClick={() => handleClickNumber(6)}>
            6
          </div>
          <div className='number-item' onClick={() => handleClickNumber(7)}>
            7
          </div>
          <div className='number-item' onClick={() => handleClickNumber(8)}>
            8
          </div>
          <div className='number-item' onClick={() => handleClickNumber(9)}>
            9
          </div>
          <div className=''></div>
          <div className='number-item' onClick={() => handleClickNumber(0)}>
            0
          </div>
          <div
            className='number-item delete-number'
            onClick={handleDeleteNumber}
          >
            <FaDeleteLeft style={{ fontSize: 32 }} />
          </div>
        </div>
      </div>
      <div className='login-footer'>
        <button className='pin-link' onClick={() => navigate('/login')}>
          Đăng nhập bằng mật khẩu
        </button>
      </div>
    </div>
  );
};

export default LoginWithPINPage;
