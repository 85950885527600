import React, { useEffect, useState } from 'react';
import '../../css/view/debit/DebtPage.css';
import { FaChevronLeft } from 'react-icons/fa';
import { EnumDebtTab } from '../../config';
import { useNavigate } from 'react-router';
import { BaseLoading, BorrowTab, LoanTab } from '../../components';
import { getDebtReportService } from '../../services/report.service';
import { useDispatch } from 'react-redux';

const ReportDebt = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(EnumDebtTab.loan);

  const getData = async () => {
    const res = await getDebtReportService();
    if (res && res.success) {
      setReportData(res.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <div id='debt-page'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>Theo dõi vay/nợ</div>
      </div>
      {reportData ? (
        <div className='debt-content d-flex flex-column ps-3 pe-3'>
          <div className='d-flex justify-content-between'>
            <div
              className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
                tab === EnumDebtTab.loan ? 'active' : null
              }`}
              onClick={() => setTab(EnumDebtTab.loan)}
            >
              CHO VAY
            </div>
            <div
              className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
                tab === EnumDebtTab.borrow ? 'active' : null
              }`}
              onClick={() => setTab(EnumDebtTab.borrow)}
            >
              CÒN NỢ
            </div>
          </div>
          {tab === EnumDebtTab.loan && (
            <LoanTab
              data={reportData}
              navigate={navigate}
              dispatch={dispatch}
            />
          )}
          {tab === EnumDebtTab.borrow && (
            <BorrowTab
              data={reportData}
              navigate={navigate}
              dispatch={dispatch}
            />
          )}
        </div>
      ) : null}
      {isLoading ? <BaseLoading /> : null}
    </div>
  );
};

export default ReportDebt;
