import {
  AddDebtPage,
  AddTransactionPage,
  BudgetDetail,
  BudgetFormPage,
  BudgetPage,
  DebtPage,
  HomePage,
  InfoPage,
  ListTransactionPage,
  LoginPage,
  LoginWithPINPage,
  MePage,
  OtherPage,
  ReportByCategories,
  ReportCategoryDetail,
  ReportCurrentFinances,
  ReportDebt,
  ReportDebtDetail,
  ReportPage,
  ReportRevenueExpenditure,
  ReportRevenueExpenditureDetail,
  ReportSpendingAnalysis,
  ReportSpendingDetail,
} from '../views';

// Auth routes
const authRoutes = [
  { path: '/login', component: LoginPage },
  { path: '/register', component: LoginPage },
  { path: '/login-pin', component: LoginWithPINPage },
];

// Routes
const routes = [
  { path: '/budget', component: BudgetPage },
  { path: '/budget/detail', component: BudgetDetail },
  { path: '/budget/add', component: BudgetFormPage },
  { path: '/debt', component: DebtPage },
  { path: '/debt/add', component: AddDebtPage },
  { path: '/report', component: ReportPage },
  { path: '/report/revenue-expenditure', component: ReportRevenueExpenditure },
  {
    path: '/report/revenue-expenditure-detail',
    component: ReportRevenueExpenditureDetail,
  },
  {
    path: '/report/spending-analysis',
    component: () => <ReportSpendingAnalysis />,
  },
  {
    path: '/report/revenue-analysis',
    component: () => <ReportSpendingAnalysis isRevenue={true} />,
  },
  {
    path: '/report/debt',
    component: ReportDebt,
  },
  {
    path: '/report/spending-detail',
    component: () => <ReportSpendingDetail />,
  },
  {
    path: '/report/revenue-detail',
    component: () => <ReportSpendingDetail isRevenue={true} />,
  },
  {
    path: '/report/categories',
    component: ReportByCategories,
  },
  {
    path: '/report/categories-detail',
    component: ReportCategoryDetail,
  },
  { path: '/report/current-finances', component: ReportCurrentFinances },
  { path: '/report/debt-detail', component: ReportDebtDetail },
  { path: '/other', component: OtherPage },
  { path: '/info', component: HomePage },
  { path: '/transaction', component: AddTransactionPage },
  { path: '/transaction/detail', component: ListTransactionPage },
  { path: '/me', component: MePage },
  { path: '/', component: HomePage },
  { path: '*', component: InfoPage },
];

export { authRoutes, routes };
