import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTransaction,
  transactionSelector,
  updateTransactionEditMode,
} from '../../store/reducer/transactionSlice';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { EnumEditMode, EnumTransactionType } from '../../config';

const ReportSpendingDetail = ({ isRevenue }) => {
  const { lstTransaction, detailDate } = useSelector(transactionSelector);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Sửa giao dịch
  const editTransaction = (item) => {
    dispatch(setSelectedTransaction(item));
    dispatch(updateTransactionEditMode(EnumEditMode.edit));
    navigate('/transaction');
  };

  const filterData = () => {
    if (lstTransaction) {
      let lstTransactionFilter = detailDate
        ? lstTransaction.filter((item) => {
            const date = new Date(item.refDate);
            const dateString = `${
              date.getDate().toString().length === 1
                ? '0' + date.getDate()
                : date.getDate()
            }/${
              (date.getMonth() + 1).toString().length === 1
                ? '0' + (date.getMonth() + 1).toString()
                : (date.getMonth() + 1).toString()
            }/${date.getFullYear()}`;
            if (
              (isRevenue &&
                (item.transactionType === EnumTransactionType.pay ||
                  item.transactionType === EnumTransactionType.loan ||
                  item.transactionType === EnumTransactionType.repayment)) ||
              (!isRevenue &&
                (item.transactionType === EnumTransactionType.receive ||
                  item.transactionType === EnumTransactionType.debtCollection ||
                  item.transactionType === EnumTransactionType.borrow))
            ) {
              return false;
            }
            return dateString === detailDate;
          })
        : lstTransaction;
      setData(lstTransactionFilter);
    }
  };

  useEffect(() => {
    filterData();
  }, [lstTransaction, detailDate]);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>{detailDate}</div>
      </div>
      <div className='report-content pt-2'>
        {data && data.length > 0
          ? data.map((tran, j) => {
              return (
                <div
                  className='spending-detail-item d-flex justify-content-between ps-3 pe-3 pt-2 pb-2 align-items-center'
                  onClick={() => {
                    if (
                      tran.transactionType === EnumTransactionType.pay ||
                      tran.transactionType === EnumTransactionType.receive
                    ) {
                      editTransaction(tran);
                    }
                  }}
                  key={j}
                >
                  <div className='d-flex align-items-center'>
                    <img
                      className='me-2'
                      src={tran.categoryID.image}
                      height={40}
                      width={40}
                      alt='category'
                    />
                    <div className='d-flex flex-column'>
                      <span className='trans-cate'>
                        {tran.categoryID.description}
                      </span>
                      <span className='trans-desc'>
                        <i>{tran.description}</i>
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      tran.transactionType === EnumTransactionType.pay ||
                      tran.transactionType === EnumTransactionType.loan ||
                      tran.transactionType === EnumTransactionType.repayment
                        ? 'pay-amount'
                        : 'receive-amount'
                    }
                  >
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(tran.amount)}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ReportSpendingDetail;
