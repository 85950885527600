import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../css/layout/BottomBar.css';
import {
  FaHouse,
  FaMoneyCheckDollar,
  FaChartLine,
  FaWindowRestore,
  FaBookOpen,
  FaBars,
} from 'react-icons/fa6';

const BottomBar = () => {
  return (
    <div className='ps-3 pe-3 pt-3 pb-4 d-flex bottom-bar'>
      <NavLink to='/' className='bottom-link'>
        <FaHouse />
        <span>Tổng quan</span>
      </NavLink>
      <NavLink to='/transaction/detail' className='bottom-link'>
        <FaBookOpen />
        <span>Giao dịch</span>
      </NavLink>
      <NavLink to='/debt' className='bottom-link'>
        <FaMoneyCheckDollar />
        <span>Vay nợ</span>
      </NavLink>
      <NavLink to='/budget' className='bottom-link'>
        <FaWindowRestore />
        <span>Ngân sách</span>
      </NavLink>
      <NavLink to='/report' className='bottom-link'>
        <FaChartLine />
        <span>Báo cáo</span>
      </NavLink>
      <NavLink to='/me' className='bottom-link'>
        <FaBars />
        <span>Menu</span>
      </NavLink>
      {/* <div to='/other' className='bottom-link' onClick={handleLogOut}>
        <FaRightFromBracket />
        <span>Đăng xuất</span>
      </div> */}
    </div>
  );
};

export default BottomBar;
