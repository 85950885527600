import React, { useEffect, useState } from 'react';
import '../../css/view/report/ReportCurrentFinances.css';
import { useNavigate } from 'react-router';
import { FaChevronLeft } from 'react-icons/fa';
import { getCurrentFinancesService } from '../../services/report.service';
import { BaseLoading } from '../../components';

const ReportCurrentFinances = () => {
  const navigate = useNavigate();
  const [detail, setDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const res = await getCurrentFinancesService();
    if (res && res.success) {
      setDetail(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>Tài chính hiện tại</div>
      </div>
      <div className='report-content p-3'>
        {isLoading ? (
          <BaseLoading />
        ) : detail ? (
          <>
            <div className='current-finances mb-3'>
              <div className='finance-item header ps-3 pe-3 pt-2 pb-2'>
                <span>
                  Tài chính hiện tại{' '}
                  <span className='note ms-1'>(1) - (2)</span>
                </span>
                <span className='amount'>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(detail.current.current)}
                </span>
              </div>
            </div>
            <div className='current-amount mb-3'>
              <div className='finance-item header ps-3 pe-3 pt-2 pb-2'>
                <span>
                  Tổng có <span className='note ms-1'>(1)</span>
                </span>
                <span className='amount'>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(detail.total.total)}
                </span>
              </div>
              {detail.total.money > 0 ? (
                <div className='finance-item ps-3 pe-3 pt-2 pb-2'>
                  <span>
                    <img
                      className='me-2'
                      src='/images/khac.png'
                      height='32'
                      width='32'
                      alt='category'
                    />
                    Tài khoản
                  </span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(detail.total.money)}
                  </span>
                </div>
              ) : null}
              {detail.total.loan > 0 ? (
                <div className='finance-item ps-3 pe-3 pt-2 pb-2'>
                  <span>
                    <img
                      className='me-2'
                      src='/images/cho-vay.png'
                      height='32'
                      width='32'
                      alt='category'
                    />
                    Cho vay
                  </span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(detail.total.loan)}
                  </span>
                </div>
              ) : null}
            </div>
            <div className='current-loan mb-2'>
              <div className='finance-item header ps-3 pe-3 pt-2 pb-2'>
                <span>
                  Tổng nợ <span className='note ms-1'>(2)</span>
                </span>
                <span className='amount'>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(detail.liabilities.liabilities)}
                </span>
              </div>
              {detail.liabilities.liabilities > 0 ? (
                <div className='finance-item ps-3 pe-3 pt-2 pb-2'>
                  <span>
                    <img
                      className='me-2'
                      src='/images/di-vay.png'
                      height='32'
                      width='32'
                      alt='category'
                    />
                    Nợ phải trả
                  </span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(detail.liabilities.liabilities)}
                  </span>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReportCurrentFinances;
