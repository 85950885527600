import axios from 'axios';
import constants from '../config/constants';

export const loginService = async (data) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/auth/login`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const registerService = async (data) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/auth/register`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editService = async (data) => {
  try {
    const res = await axios.put(
      `${constants.API_URL}/api/${constants.API_VER}/auth/edit`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserService = async () => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/auth`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkPasscode = async (data) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/auth/passcode`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
