const constants = {
  // API_URL: 'http://192.168.0.103:8000',
  API_URL: 'https://phamvanlinh-server.onrender.com',
  API_VER: 'v1',
  TOKEN_NAME: 'userToken',
  USERNAME: 'username',
  ALWAYSPIN: 'alwaysPIN',
};

export default constants;
