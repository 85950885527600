import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  FaCalendarAlt,
  FaCheck,
  FaChevronLeft,
  FaQuestion,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { SelectCategory } from '../../components';
import { EnumTransactionType } from '../../config';
import { addBudgetService } from '../../services/budget.service';

const BudgetFormPage = () => {
  const [budget, setBudget] = useState({ amount: 0 });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isShowCategoryForm, setIsShowCategoryForm] = useState(false);

  const navigate = useNavigate();

  /**
   * Set giá trị mắc định cho ngân sách
   */
  const setDefaultBudget = () => {
    const today = new Date();
    // Lấy ngày đầu tiên của tháng
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    // Đặt giờ, phút, giây và mili giây của firstDayOfMonth thành 0 để chỉ lấy ngày
    firstDayOfMonth.setHours(0, 0, 0, 0);

    // Lấy ngày cuối cùng của tháng
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    // Đặt giờ, phút, giây và mili giây của lastDayOfMonth thành giá trị tối đa để lấy ngày cuối cùng của tháng
    lastDayOfMonth.setHours(23, 59, 59, 999);

    setBudget({
      categoryID: null,
      amount: 0,
      fromDate: firstDayOfMonth,
      toDate: lastDayOfMonth,
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    });
  };

  /**
   * Thay đổi tháng
   * @param {*} e
   */
  const onChangeMonth = (e) => {
    const month = parseInt(e.target.value);
    const firstDayOfMonth = new Date(budget.year, month - 1, 1);
    // Đặt giờ, phút, giây và mili giây của firstDayOfMonth thành 0 để chỉ lấy ngày
    firstDayOfMonth.setHours(0, 0, 0, 0);

    // Lấy ngày cuối cùng của tháng
    const lastDayOfMonth = new Date(budget.year, month, 0);
    // Đặt giờ, phút, giây và mili giây của lastDayOfMonth thành giá trị tối đa để lấy ngày cuối cùng của tháng
    lastDayOfMonth.setHours(23, 59, 59, 999);

    setBudget((prev) => {
      return {
        ...prev,
        ...{ month: month, fromDate: firstDayOfMonth, toDate: lastDayOfMonth },
      };
    });
  };

  /**
   * Thay đổi năm
   * @param {*} e
   */
  const onChangeYear = (e) => {
    const year = parseInt(e.target.value);
    if (year < 9999 && year > 0) {
      const firstDayOfMonth = new Date(year, budget.month - 1, 1);
      // Đặt giờ, phút, giây và mili giây của firstDayOfMonth thành 0 để chỉ lấy ngày
      firstDayOfMonth.setHours(0, 0, 0, 0);

      // Lấy ngày cuối cùng của tháng
      const lastDayOfMonth = new Date(year, budget.month, 0);
      // Đặt giờ, phút, giây và mili giây của lastDayOfMonth thành giá trị tối đa để lấy ngày cuối cùng của tháng
      lastDayOfMonth.setHours(23, 59, 59, 999);

      setBudget((prev) => {
        return {
          ...prev,
          ...{
            year: year,
            fromDate: firstDayOfMonth,
            toDate: lastDayOfMonth,
          },
        };
      });
    }
  };

  /**
   * Lưu
   */
  const onSubmit = async () => {
    if (validateData()) {
      const res = await addBudgetService(budget);
      if (res.success) {
        navigate('/budget');
      } else {
        alert(res.message);
      }
    }
  };

  /**
   * Validate dữ liệu
   * @returns
   */
  const validateData = () => {
    if (!budget.amount) {
      alert('Số tiền không được để trồng.');
      return false;
    }
    if (!budget.categoryID) {
      alert('Hạng mục không được để trống.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    setDefaultBudget();
  }, []);

  return (
    <div id='add-transaction' className='p-3'>
      <div className='frm-header mb-3'>
        <FaChevronLeft onClick={() => navigate(-1)} />
        <div className='frm-title'>Thêm ngân sách</div>
        <FaCheck onClick={onSubmit} />
      </div>
      <div className='frm-trans-content'>
        <div className='input-gr d-flex flex-column w-100'>
          <div className='w-100 text-end'>Số tiền</div>
          <div className='frm-money-gr mb-3 d-flex align-items-center'>
            <CurrencyInput
              className={`frm-money`}
              id='input-example'
              name='input-name'
              defaultValue={budget.amount}
              decimalsLimit={2}
              onValueChange={(value) =>
                setBudget((prev) => {
                  return { ...prev, amount: value };
                })
              }
            />
            <span>₫</span>
          </div>
          <div className='add-group mb-3'>
            <div className='group-icon'>
              {selectedCategory ? (
                <img
                  src={selectedCategory.image}
                  width={48}
                  height={48}
                  className='rounded'
                  alt='icon'
                />
              ) : (
                <FaQuestion />
              )}
            </div>
            <button
              className={`group-item ${selectedCategory ? 'selected' : null}`}
              onClick={() => setIsShowCategoryForm(true)}
            >
              {selectedCategory
                ? selectedCategory.description
                : 'Chọn hạng mục'}
            </button>
          </div>
          <div className='add-group mb-3'>
            <div className='group-icon'>
              <FaCalendarAlt />
            </div>
            <select
              className='group-item'
              value={budget.month}
              onChange={onChangeMonth}
            >
              <option value='1'>Tháng 1</option>
              <option value='2'>Tháng 2</option>
              <option value='3'>Tháng 3</option>
              <option value='4'>Tháng 4</option>
              <option value='5'>Tháng 5</option>
              <option value='6'>Tháng 6</option>
              <option value='7'>Tháng 7</option>
              <option value='8'>Tháng 8</option>
              <option value='9'>Tháng 9</option>
              <option value='10'>Tháng 10</option>
              <option value='11'>Tháng 11</option>
              <option value='12'>Tháng 12</option>
            </select>
          </div>
          <div className='add-group mb-3'>
            <div className='group-icon'>
              <FaCalendarAlt />
            </div>
            <input
              type='number'
              min={2001}
              max={9999}
              className='group-item'
              value={budget.year}
              onChange={onChangeYear}
            />
          </div>
        </div>
      </div>
      {isShowCategoryForm ? (
        <SelectCategory
          transactionType={EnumTransactionType.pay}
          onBackClick={() => setIsShowCategoryForm(false)}
          onChangeCategory={(value) => {
            setSelectedCategory(value);
            setBudget((prev) => {
              return { ...prev, categoryID: value._id };
            });
            setIsShowCategoryForm(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default BudgetFormPage;
