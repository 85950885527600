import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTransaction,
  transactionSelector,
  updateTransactionEditMode,
} from '../../store/reducer/transactionSlice';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { EnumEditMode, EnumTransactionType } from '../../config';
import commonFunction from '../../common/commonFunction';

const ReportCategoryDetail = () => {
  const { lstTransaction, detailCategory, filterDateType, lstCategory } =
    useSelector(transactionSelector);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState('');
  // const [filterTime, setFilterTime] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Sửa giao dịch
  const editTransaction = (item) => {
    dispatch(setSelectedTransaction(item));
    dispatch(updateTransactionEditMode(EnumEditMode.edit));
    navigate('/transaction');
  };

  const filterData = () => {
    let lstDate = [];
    let lstDetails = [];
    const filterTime = commonFunction.getFilterDate(filterDateType);
    if (lstTransaction) {
      let lstTransactionFilter = filterTime
        ? lstTransaction.filter(
            (item) =>
              new Date(item.refDate) > new Date(filterTime.startTime) &&
              new Date(item.refDate) < new Date(filterTime.endTime) &&
              item.categoryID._id === detailCategory
          )
        : lstTransaction;
      lstTransactionFilter.forEach((item) => {
        const date = new Date(item.refDate);
        const dateString = `${
          date.getDate().toString().length === 1
            ? '0' + date.getDate()
            : date.getDate()
        }/${
          (date.getMonth() + 1).toString().length === 1
            ? '0' + (date.getMonth() + 1).toString()
            : (date.getMonth() + 1).toString()
        }/${date.getFullYear()}`;
        if (lstDate.includes(dateString)) {
          let detail = lstDetails.find((item) => item.date === dateString);
          if (detail) {
            if (
              item.transactionType === EnumTransactionType.pay ||
              item.transactionType === EnumTransactionType.loan ||
              item.transactionType === EnumTransactionType.repayment
            ) {
              detail.payAmount += item.amount;
            } else {
              detail.receiveAmount += item.amount;
            }
            detail.transaction.push(item);
          }
        } else {
          lstDetails.push({
            date: dateString,
            payAmount:
              item.transactionType === EnumTransactionType.pay ||
              item.transactionType === EnumTransactionType.loan ||
              item.transactionType === EnumTransactionType.repayment
                ? item.amount
                : 0,
            receiveAmount:
              item.transactionType === EnumTransactionType.pay ||
              item.transactionType === EnumTransactionType.loan ||
              item.transactionType === EnumTransactionType.repayment
                ? 0
                : item.amount,
            transaction: [item],
          });
          lstDate.push(dateString);
        }
      });
    }
    setData(lstDetails);
  };

  useEffect(() => {
    const category = lstCategory.find((item) => item._id === detailCategory);
    if (category) {
      setTitle(category.description);
    }
    filterData();
  }, [filterDateType, detailCategory, lstCategory, lstTransaction]);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>{title}</div>
      </div>
      <div className='report-content pt-2'>
        {data && data.length > 0
          ? data.map((item, i) => {
              return (
                <div className='trans-item mb-2 pb-3 ps-md-3 pe-md-3' key={i}>
                  <div className='trans-head d-flex justify-content-between align-items-center ps-3 pe-3 pt-1 pb-1'>
                    <h1>{item.date}</h1>
                    <div className='d-flex flex-column align-items-end'>
                      {item.receiveAmount > 0 ? (
                        <span className='receive-amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(item.receiveAmount)}
                        </span>
                      ) : null}
                      {item.payAmount > 0 ? (
                        <span className='pay-amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(item.payAmount)}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {item.transaction.map((tran, j) => {
                    return (
                      <div
                        className='d-flex justify-content-between ps-4 pe-3 pt-1 pb-2 align-items-center'
                        onClick={() => {
                          if (
                            tran.transactionType === EnumTransactionType.pay ||
                            tran.transactionType === EnumTransactionType.receive
                          ) {
                            editTransaction(tran);
                          }
                        }}
                        key={j}
                      >
                        <div className='d-flex align-items-center'>
                          <img
                            className='me-2'
                            src={tran.categoryID.image}
                            height={40}
                            width={40}
                            alt='category'
                          />
                          <div className='d-flex flex-column'>
                            <span className='trans-cate'>
                              {tran.categoryID.description}
                            </span>
                            <span className='trans-desc'>
                              <i>{tran.description}</i>
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            tran.transactionType === EnumTransactionType.pay ||
                            tran.transactionType === EnumTransactionType.loan ||
                            tran.transactionType ===
                              EnumTransactionType.repayment
                              ? 'pay-amount'
                              : 'receive-amount'
                          }
                        >
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(tran.amount)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ReportCategoryDetail;
