import React, { useState } from 'react';
import '../../css/component/debt/DebtObject.css';
import { FaCheck, FaChevronLeft } from 'react-icons/fa';
import { EnumTransactionType } from '../../config';
import { transactionSelector } from '../../store/reducer/transactionSlice';
import { useSelector } from 'react-redux';

const DebtObject = ({ transType, onBack, onSelect }) => {
  const [objectName, setObjectName] = useState('');
  const { lstObject } = useSelector(transactionSelector);

  return (
    <div id='debt-object'>
      <div className='debt-object-content p-3'>
        <div className='frm-header mb-3'>
          <FaChevronLeft onClick={onBack} />
          <div>
            {transType === EnumTransactionType.loan ||
            transType === EnumTransactionType.debtCollection
              ? 'Người vay'
              : 'Người cho vay'}
          </div>
          <FaCheck onClick={() => onSelect({ objectName: objectName })} />
        </div>
        <input
          className='login-input'
          type='text'
          placeholder='Tìm hoặc nhập tên đối tượng mới'
          value={objectName}
          onChange={(e) => setObjectName(e.target.value)}
        />
        <div className='list-object'>
          {lstObject && lstObject.length > 0
            ? lstObject.map((item, i) => {
                return (
                  <div
                    className='d-flex align-items-center mb-2'
                    key={item._id}
                    onClick={() => onSelect(item)}
                  >
                    <div className='object-name-circle me-2'>
                      {item.objectName.slice(0, 1)}
                    </div>
                    <div className='day-detail'>
                      <span>{item.objectName}</span>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default DebtObject;
