import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducer/authSlice';
import transactionReducer from './reducer/transactionSlice';

const store = configureStore({
  reducer: {
    authReducer,
    transactionReducer,
  },
});

// Export
export default store;
