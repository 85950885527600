import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../store/reducer/transactionSlice';
import { getDebtDetailService } from '../../services/report.service';
import { EnumTransactionType } from '../../config';
import { BaseLoading } from '../../components';

const ReportDebtDetail = () => {
  const navigate = useNavigate();
  const { detailDebt } = useSelector(transactionSelector);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const res = await getDebtDetailService(detailDebt);
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  const convertDateToString = (refDate) => {
    const date = new Date(refDate);
    const dateString = `${
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
    }/${
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString()
    }/${date.getFullYear()}`;
    return dateString;
  };

  const getImageSrc = (type) => {
    switch (type) {
      case EnumTransactionType.loan:
        return '/images/cho-vay.png';
      case EnumTransactionType.debtCollection:
        return '/images/thu-no.png';
      case EnumTransactionType.borrow:
        return '/images/di-vay.png';
      case EnumTransactionType.repayment:
        return '/images/tra-no.png';
      default:
        break;
    }
  };

  const getDebtTypeDesc = (type) => {
    switch (type) {
      case EnumTransactionType.loan:
        return 'Cho vay';
      case EnumTransactionType.debtCollection:
        return 'Thu nợ';
      case EnumTransactionType.borrow:
        return 'Đi vay';
      case EnumTransactionType.repayment:
        return 'Trả nợ';
      default:
        break;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [detailDebt]);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>
          {data ? data.lstTransaction[0].object.objectName : null}
        </div>
      </div>
      {isLoading ? (
        <BaseLoading />
      ) : (
        <div className='report-content pt-2 ps-3 pe-3'>
          {data ? (
            <>
              <div className='current-debt p-3 mb-3'>
                <p className='mb-1'>Hiện tại</p>
                <div className='d-flex justify-content-between'>
                  <span>
                    Tổng{' '}
                    {detailDebt.type === EnumTransactionType.loan
                      ? 'cho'
                      : 'đi'}{' '}
                    vay
                  </span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(
                      detailDebt.type === EnumTransactionType.loan
                        ? data.loanAmount
                        : data.borrowAmount
                    )}
                  </span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span>
                    Đã{' '}
                    {detailDebt.type === EnumTransactionType.loan
                      ? 'thu'
                      : 'trả'}{' '}
                    (
                    {Math.round(
                      ((detailDebt.type === EnumTransactionType.loan
                        ? data.collectAmount
                        : data.repaymentAmount) /
                        (detailDebt.type === EnumTransactionType.loan
                          ? data.loanAmount
                          : data.borrowAmount)) *
                        100
                    )}
                    %)
                  </span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(
                      detailDebt.type === EnumTransactionType.loan
                        ? data.collectAmount
                        : data.repaymentAmount
                    )}
                  </span>
                </div>
                {data.loanAmount - data.collectAmount > 0 ? (
                  <div className='d-flex justify-content-between'>
                    <span>
                      Cần{' '}
                      {detailDebt.type === EnumTransactionType.loan
                        ? 'thu'
                        : 'trả'}
                    </span>
                    <span className='amount'>
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(
                        (detailDebt.type === EnumTransactionType.loan
                          ? data.loanAmount
                          : data.borrowAmount) -
                          (detailDebt.type === EnumTransactionType.loan
                            ? data.collectAmount
                            : data.repaymentAmount)
                      )}
                    </span>
                  </div>
                ) : null}
                <div class='show-detail d-flex justify-content-end'>
                  <a href='/transaction/detail'>
                    {detailDebt.type === EnumTransactionType.loan
                      ? 'Thu'
                      : 'Trả'}{' '}
                    nợ
                    <FaArrowRightLong className='ms-1' />
                  </a>
                </div>
              </div>
              <div className='lst-debt-detail'>
                <p className='ps-3 pe-3 pt-3 mb-1'>Chi tiết</p>
                {data.lstTransaction
                  ? data.lstTransaction.map((item) => {
                      return (
                        <div className='debt-detail-item  mb-2' key={item._id}>
                          <div className='d-flex justify-content-between ps-3 pe-3 pt-1 pb-2 align-items-center'>
                            <div className='d-flex align-items-center'>
                              <img
                                className='me-2'
                                src={getImageSrc(
                                  item.transaction.transactionType
                                )}
                                height='40'
                                width='40'
                                alt='category'
                              />
                              <div className='d-flex flex-column'>
                                <span className='trans-cate'>
                                  {getDebtTypeDesc(
                                    item.transaction.transactionType
                                  )}
                                </span>
                                <span className='trans-desc'>
                                  <i>{item.transaction.description}</i>
                                </span>
                              </div>
                            </div>
                            <div className='text-end'>
                              <div>
                                {convertDateToString(item.transaction.refDate)}
                              </div>
                              <div
                                className={
                                  item.transaction.transactionType ===
                                    EnumTransactionType.loan ||
                                  item.transaction.transactionType ===
                                    EnumTransactionType.repayment
                                    ? 'pay-amount'
                                    : 'receive-amount'
                                }
                              >
                                {new Intl.NumberFormat('vi-VN', {
                                  style: 'currency',
                                  currency: 'VND',
                                }).format(item.transaction.amount)}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ReportDebtDetail;
