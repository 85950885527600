import { memo, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const PieChartCustom = ({ data, chartName }) => {
  const chartRef = useRef(null);

  const [customOption, setCustomOption] = useState(null);

  useEffect(() => {
    setCustomOption({
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '0%',
        left: 'center',
      },
      series: [
        {
          name: chartName,
          type: 'pie',
          radius: ['35%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 8,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          center: ['50%', '60%'],
          data,
        },
      ],
    });
  }, [data, chartName]);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    if (customOption) {
      chart.setOption(customOption);
    }
  }, [customOption]);

  return (
    <div
      className='pie-chart'
      ref={chartRef}
      style={{ width: '100%', minHeight: '450px' }}
    ></div>
  );
};

export default memo(PieChartCustom);
