import React, { memo, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const LineChartCustom = ({
  data,
  xLabels,
  xUnit,
  yUnit,
  isRed,
  showXLabels,
}) => {
  const chartRef = useRef(null);

  const [customOption, setCustomOption] = useState(null);

  useEffect(() => {
    setCustomOption({
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        show: showXLabels,
        type: 'category',
        data: xLabels,
        name: xUnit,
        nameLocation: 'center',
        nameGap: 32,
      },
      grid: { left: '4%', right: '4%', containLabel: true },
      yAxis: {
        type: 'value',
        name: yUnit,
      },
      series: [
        {
          data: data,
          type: 'line',
          showSymbol: false,
          areaStyle: {
            color: isRed ? '#ff4d6d66' : '#02c39a66',
          },
          smooth: 0.5,
        },
      ],
      color: isRed ? '#ff4d6d' : '#02c39a',
    });
  }, [data, xLabels, xUnit, yUnit, isRed, showXLabels]);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    if (customOption) {
      chart.setOption(customOption);
    }
  }, [customOption]);

  return (
    <div
      className='line-chart'
      ref={chartRef}
      style={{ width: '100%', minHeight: '400px' }}
    ></div>
  );
};

export default memo(LineChartCustom);
