import React, { memo, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const BarChartCustom = ({ data, xlabels, yUnit, xUnit, chartName }) => {
  const chartRef = useRef();

  const [customOption, setCustomOption] = useState(null);

  useEffect(() => {
    setCustomOption({
      tooltip: {
        trigger: 'item',
      },
      xAxis: {
        type: 'category',
        data: xlabels,
        name: xUnit,
        nameLocation: 'center',
        nameGap: 32,
      },
      yAxis: {
        type: 'value',
        name: yUnit,
      },
      grid: { left: '2%', right: '2%', containLabel: true },
      series: [
        {
          name: chartName,
          type: 'bar',
          itemStyle: {
            borderRadius: 6,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data,
        },
      ],
    });
  }, [data, xlabels, xUnit, yUnit, chartName]);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    if (customOption) {
      chart.setOption(customOption);
    }
  }, [customOption]);

  return (
    <div
      className='bar-chart'
      ref={chartRef}
      style={{ width: '100%', minHeight: '400px' }}
    ></div>
  );
};

export default memo(BarChartCustom);
