import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../store/reducer/transactionSlice';
import { getRevenueExpenditureServiceDetail } from '../../services/report.service';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import commonFunction from '../../common/commonFunction';
import { BarNegativeChartCustom, MonthDetail } from '../../components';
import moment from 'moment';

const ReportRevenueExpenditureDetail = () => {
  const { revenueExpenditureDetailTime } = useSelector(transactionSelector);
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [label, setLabel] = useState(null);

  const navigate = useNavigate();

  const getData = async () => {
    if (revenueExpenditureDetailTime) {
      const filterCondition = JSON.parse(revenueExpenditureDetailTime);
      const res = await getRevenueExpenditureServiceDetail(filterCondition);
      if (res && res.success) {
        if (res.data) {
          const lstData1 = [],
            lstData2 = [],
            lstLabel = [],
            lstData = [];
          for (let i = 0; i < res.data.length; i++) {
            const el = res.data[i];
            lstData1.push(el.amount.receiveAmount / 1000000);
            lstData2.push(-el.amount.payAmount / 1000000);
            lstLabel.push(i + 1);
            lstData.push({
              title: `Tuần ${i + 1}`,
              description: `${moment(new Date(el.weekStart)).format(
                'DD/MM/YYYY'
              )} - ${moment(new Date(el.weekEnd)).format('DD/MM/YYYY')}`,
              receiveAmount: el.amount.receiveAmount,
              payAmount: el.amount.payAmount,
            });
          }
          setData1(lstData1);
          setData2(lstData2);
          setLabel(lstLabel);
          setData(lstData);
        }
      }
    }
  };

  const getTitle = () => {
    let title = '';
    if (revenueExpenditureDetailTime) {
      const filterCondition = JSON.parse(revenueExpenditureDetailTime);
      title = `${moment(new Date(filterCondition.startTime)).format(
        'DD/MM/YYYY'
      )} - ${moment(new Date(filterCondition.endTime)).format('DD/MM/YYYY')}`;
    }

    return title;
  };

  useEffect(() => {
    getData();
  }, [revenueExpenditureDetailTime]);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>{getTitle()}</div>
      </div>
      <div className='report-content p-3'>
        {/* Chart */}
        {data1 && data2 && label ? (
          <div className='loan-amount p-3 mb-3'>
            <BarNegativeChartCustom
              data1={data1}
              data2={data2}
              xLabels={label}
              xUnit='Tuần'
              yUnit='Triệu VND'
              caption1='Thu'
              caption2='Chi'
            />
          </div>
        ) : null}
        {data ? (
          <div className='revenue-detail d-flex flex-column-reverse'>
            {data.map((item, i) => {
              if (item.payAmount !== 0 || item.receiveAmount !== 0) {
                return <MonthDetail key={i} detail={item} onClick={() => {}} />;
              }
              return null;
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ReportRevenueExpenditureDetail;
