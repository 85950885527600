import React, { useState } from 'react';
import '../../css/view/LoginPage.css';
import {
  FaLongArrowAltRight,
  FaLongArrowAltLeft,
  FaTimes,
} from 'react-icons/fa';
import { editService } from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from '../../store/reducer/authSlice';
import { useNavigate } from 'react-router-dom';

const InfoPage = () => {
  const [step, setStep] = useState(1);

  const [lstPIN, setLstPIN] = useState([null, null, null, null]);
  const [isUsePIN, setIsUsePIN] = useState(false);
  const [info, setInfo] = useState({
    fullName: '',
    passcode: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickNumber = (n) => {
    setLstPIN((prev) => {
      let isFirst = true;
      prev = prev.map((item) => {
        if (!item && isFirst && item !== 0) {
          isFirst = false;
          return n;
        }
        return item;
      });

      return prev;
    });
  };

  const validateDataBeforeSave = () => {
    if (isUsePIN) {
      let pin = lstPIN.join('');
      if (pin.length !== 4) {
        alert('Mã PIN không hợp lệ');
        return false;
      }
    }
    return true;
  };

  const handleUpdateInfo = async () => {
    if (validateDataBeforeSave()) {
      let infoData = info;
      if (isUsePIN) {
        infoData.passcode = lstPIN.join('');
      }
      const res = await editService(infoData);
      if (!res.success) {
        alert(res.message);
      } else {
        dispatch(updateUserInfo(res.data));
        navigate('/');
      }
    }
  };

  const handleDeleteNumber = () => {
    setLstPIN([null, null, null, null]);
  };

  return (
    <div id='info-page'>
      <div className='login-input-group'>
        {step === 1 ? (
          <>
            <input
              className='login-input'
              type='text'
              name='fullName'
              placeholder='Họ và tên'
              value={info.fullName}
              onChange={(e) =>
                setInfo((prev) => {
                  return { ...prev, fullName: e.target.value };
                })
              }
            />
            <div className='checkbox-item'>
              <input
                type='checkbox'
                name='isUsePIN'
                checked={isUsePIN}
                id='checkbox'
                onChange={(e) => setIsUsePIN(e.target.checked)}
              />
              <label htmlFor='checkbox'>Sử dụng mã PIN</label>
            </div>
            {isUsePIN ? (
              <button className='pin-setting' onClick={() => setStep(2)}>
                Thiết lập mã PIN <FaLongArrowAltRight className='arrow-right' />
              </button>
            ) : null}
          </>
        ) : (
          <>
            <p className='info-header'>Thiết lập mã PIN</p>
            <div className='pin-display'>
              {lstPIN.map((n, i) => {
                return (
                  <div className='pin-item' key={i}>
                    {n || n === 0 ? n : ''}
                  </div>
                );
              })}
            </div>
            <div className='pin-number'>
              <div className='number-item' onClick={() => handleClickNumber(1)}>
                1
              </div>
              <div className='number-item' onClick={() => handleClickNumber(2)}>
                2
              </div>
              <div className='number-item' onClick={() => handleClickNumber(3)}>
                3
              </div>
              <div className='number-item' onClick={() => handleClickNumber(4)}>
                4
              </div>
              <div className='number-item' onClick={() => handleClickNumber(5)}>
                5
              </div>
              <div className='number-item' onClick={() => handleClickNumber(6)}>
                6
              </div>
              <div className='number-item' onClick={() => handleClickNumber(7)}>
                7
              </div>
              <div className='number-item' onClick={() => handleClickNumber(8)}>
                8
              </div>
              <div className='number-item' onClick={() => handleClickNumber(9)}>
                9
              </div>
              <div className=''></div>
              <div className='number-item' onClick={() => handleClickNumber(0)}>
                0
              </div>
              <div
                className='number-item delete-number'
                onClick={handleDeleteNumber}
              >
                <FaTimes style={{ fontSize: 28 }} />
              </div>
            </div>
            <button className='pin-setting' onClick={() => setStep(1)}>
              <FaLongArrowAltLeft className='arrow-left' />
              Thiết lập tên
            </button>
          </>
        )}
      </div>
      <div className='button-group'>
        <button
          className='login-button second-button'
          onClick={() => navigate('/')}
        >
          BỎ QUA
        </button>
        <button className='login-button' onClick={handleUpdateInfo}>
          HOÀN THÀNH
        </button>
      </div>
    </div>
  );
};

export default InfoPage;
