import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { EnumFilterDateType } from '../../config';
import commonFunction from '../../common/commonFunction';
import '../../css/view/report/ReportSpendingAnalysis.css';
import {
  getRevenueService,
  getSpendingService,
} from '../../services/report.service';
import { BaseLoading, DayPayDetail, LineChartCustom } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDetailDate,
  setFilterDateType,
  transactionSelector,
} from '../../store/reducer/transactionSlice';

/**
 * Phân tích chi tiêu/thu
 * @returns
 */
const ReportSpendingAnalysis = ({ isRevenue }) => {
  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState(null);
  const { filterDateType } = useSelector(transactionSelector);
  const [data, setData] = useState(null);
  const [labels, setLabels] = useState(null);
  const [details, setDetails] = useState(null);
  const [total, setTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // Thay đổi filter thời gian
  const handleChangeFilterTime = (e) => {
    dispatch(setFilterDateType(parseInt(e.target.value)));
  };

  const getChartData = async () => {
    let res = null;
    if (!isRevenue) {
      res = await getSpendingService(filterDate);
    } else {
      res = await getRevenueService(filterDate);
    }
    if (res && res.success) {
      let totalAmount = 0;
      let totalDay = 0;
      if (res.data && res.data.length > 0) {
        let labels = [];
        let datasets = [];
        let lstDetail = [];
        res.data.forEach((item) => {
          labels.push(item.refDate);
          datasets.push(item.amount / 1000000);
          totalAmount += item.amount;
          lstDetail.unshift(item);
          if (item.amount > 0) {
            totalDay += 1;
          }
        });
        setData(datasets);
        setLabels(labels);
        setDetails(lstDetail);
      }
      if (!totalDay) {
        totalDay = 1;
      }
      setTotal({
        totalAmount: totalAmount,
        mediumAmount: Math.round(totalAmount / totalDay),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterDate) {
      setIsLoading(true);
      getChartData();
    }
  }, [filterDate]);

  useEffect(() => {
    setFilterDate(commonFunction.getFilterDate(filterDateType));
  }, [filterDateType]);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>
          Phân tích {isRevenue ? 'thu' : 'chi tiêu'}
        </div>
      </div>
      <div className='mt-2 mb-2 ps-3 pe-3 d-flex justify-content-between'>
        <div className='situation-header mb-1'>
          {filterDate
            ? commonFunction.getDDMMYYY(filterDate.startTime) +
              ' - ' +
              commonFunction.getDDMMYYY(filterDate.endTime)
            : null}
        </div>
        <select
          className='select-filter-date mb-1'
          defaultValue={filterDateType}
          onChange={handleChangeFilterTime}
          disabled={isLoading}
        >
          <option value={EnumFilterDateType.today}>Hôm nay</option>
          <option value={EnumFilterDateType.yesterday}>Hôm qua</option>
          <option value={EnumFilterDateType.week}>Tuần này</option>
          <option value={EnumFilterDateType.lastWeek}>Tuần trước</option>
          <option value={EnumFilterDateType.month}>Tháng này</option>
          <option value={EnumFilterDateType.lastMonth}>Tháng trước</option>
          <option value={EnumFilterDateType.quarter}>Quý này</option>
          <option value={EnumFilterDateType.lastQuarter}>Quý truớc</option>
          <option value={EnumFilterDateType.year}>Năm nay</option>
          <option value={EnumFilterDateType.lastYear}>Năm trước</option>
          <option value={EnumFilterDateType.jan}>Tháng 1</option>
          <option value={EnumFilterDateType.feb}>Tháng 2</option>
          <option value={EnumFilterDateType.mar}>Tháng 3</option>
          <option value={EnumFilterDateType.apr}>Tháng 4</option>
          <option value={EnumFilterDateType.may}>Tháng 5</option>
          <option value={EnumFilterDateType.jun}>Tháng 6</option>
          <option value={EnumFilterDateType.jul}>Tháng 7</option>
          <option value={EnumFilterDateType.aug}>Tháng 8</option>
          <option value={EnumFilterDateType.sep}>Tháng 9</option>
          <option value={EnumFilterDateType.oct}>Tháng 10</option>
          <option value={EnumFilterDateType.nov}>Tháng 11</option>
          <option value={EnumFilterDateType.dec}>Tháng 12</option>
        </select>
      </div>
      <div className='report-content p-3'>
        {isLoading ? (
          <BaseLoading />
        ) : (
          <>
            {data ? (
              <div className='loan-amount p-3 mb-3'>
                <LineChartCustom
                  data={data}
                  xLabels={labels}
                  xUnit='Ngày'
                  yUnit='Triệu VND'
                  isRed={!isRevenue}
                  showXLabels={false}
                />
              </div>
            ) : null}
            {total ? (
              <div className='report-total-amount p-3 mb-3'>
                <div className='d-flex justify-content-between mb-2'>
                  <span>Tổng {isRevenue ? 'thu' : 'chi tiêu'}</span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(total.totalAmount)}
                  </span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span>Trung bình {isRevenue ? 'thu' : 'chi'}/ngày</span>
                  <span className='amount'>
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(total.mediumAmount)}
                  </span>
                </div>
              </div>
            ) : null}
            {details ? (
              <div className='spending-detail'>
                <div className='p-3'>
                  Chi tiết {isRevenue ? 'thu' : 'chi tiêu'}
                </div>
                {details.map((item, i) => {
                  if (item.amount > 0) {
                    return (
                      <DayPayDetail
                        item={item}
                        key={i}
                        isRevenue={isRevenue}
                        onClick={() => {
                          dispatch(setDetailDate(item.refDate));
                          navigate(
                            isRevenue
                              ? '/report/revenue-detail'
                              : '/report/spending-detail'
                          );
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportSpendingAnalysis;
