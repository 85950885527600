import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserService } from '../../services/auth.service';
import constants from '../../config/constants';
import setAuthToken from '../../utils/setAuthToken';

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (rejectWithValue) => {
    if (localStorage.getItem(constants.TOKEN_NAME)) {
      setAuthToken(localStorage.getItem(constants.TOKEN_NAME));
    }

    const res = await getUserService();
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue();
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: true,
    isAuthenticated: false,
    user: null,
    authMsg: null,
  },
  reducers: {
    updateUserInfo(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
    });
  },
});

// Reducer
const authReducer = authSlice.reducer;

// Actions
export const { updateUserInfo, logout } = authSlice.actions;

// Selector
export const authSelector = (state) => state.authReducer;

// Export reducer
export default authReducer;
