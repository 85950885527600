import React, { useEffect, useState } from 'react';
import { FaCalendarAlt, FaChevronLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { transactionSelector } from '../../store/reducer/transactionSlice';
import {
  deleteBudgetService,
  getBudgetByIDService,
} from '../../services/budget.service';
import { BaseLoading, LineChartCustom } from '../../components';
import moment from 'moment';

const BudgetDetail = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [label, setLabel] = useState(null);

  const { selectedBudget } = useSelector(transactionSelector);

  const getBudgetData = async () => {
    setIsLoading(true);
    const res = await getBudgetByIDService(selectedBudget);
    if (res.success) {
      setData(res.data);
      if (res.data.payAmounts) {
        let labels = [];
        let datasets = [];
        let tempAmount = 0;
        res.data.payAmounts.forEach((item) => {
          tempAmount += item.amount;
          labels.push(item.refDate);
          datasets.push(tempAmount / 1000000);
        });
        setChartData(datasets);
        setLabel(labels);
      }
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    const result = window.confirm('Bạn có chắc muốn xóa ngân sách này không?');
    if (result) {
      const res = await deleteBudgetService(selectedBudget);
      if (res.success) {
        navigate('/budget');
      } else {
        alert(res.message);
      }
    }
  };

  useEffect(() => {
    getBudgetData();
  }, [selectedBudget]);

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>
          {data ? data.budget.categoryID.description : null}
        </div>
      </div>
      {isLoading || !data ? (
        <BaseLoading />
      ) : (
        <div className='report-content pt-2 ps-3 pe-3'>
          <div className='loan-amount mt-3 mb-3 p-3'>
            <div className='category d-flex mb-4'>
              <img
                src={data.budget.categoryID.image}
                alt='Icon'
                width={48}
                height={48}
              />
              <div className='ms-3 d-flex flex-column flex-grow-1'>
                <span className='budget-title'>
                  {data.budget.categoryID.description}
                </span>
                <span className='budget-amount mt-2 mb-2'>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(data.budget.amount)}
                </span>
                <div className='d-flex justify-content-between mb-2'>
                  <div className='d-flex flex-column'>
                    <span className='trans-desc'>Đã chi</span>
                    <span className='amount'>
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(data.moreInfo.useAmount)}
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='trans-desc'>
                      {data.budget.amount > data.moreInfo.useAmount
                        ? 'Còn lại'
                        : 'Bội chi'}
                    </span>
                    <span className='amount'>
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(
                        data.budget.amount > data.moreInfo.useAmount
                          ? data.budget.amount - data.moreInfo.useAmount
                          : -data.budget.amount + data.moreInfo.useAmount
                      )}
                    </span>
                  </div>
                </div>
                <div className='loan-bar'>
                  <div
                    className={`${
                      data.budget.amount > data.moreInfo.useAmount
                        ? 'loan-bar-done'
                        : 'loan-bar-done loan-bar-err'
                    }`}
                    style={{
                      width: `${
                        (data.moreInfo.useAmount / data.budget.amount) * 100
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <div className='group-icon me-0'>
                <FaCalendarAlt />
              </div>
              <div className='ms-3 flex-grow-1 d-flex flex-column'>
                <span>
                  {moment(data.budget.fromDate).format('DD/MM')} -
                  {moment(data.budget.toDate).format('DD/MM')}
                </span>
                <span className='trans-desc'>
                  Còn {data.moreInfo.remainDays} ngày
                </span>
              </div>
            </div>
          </div>
          {chartData ? (
            <div className='loan-amount mt-3 mb-3 p-3'>
              <LineChartCustom
                data={chartData}
                xLabels={label}
                yUnit='Triệu VND'
                isRed={data.budget.amount < data.moreInfo.useAmount}
              />
            </div>
          ) : null}
          <div className='loan-amount mt-3 mb-3 p-3'>
            <div className='d-flex justify-content-between mb-2'>
              <span>Nên chi hàng ngày</span>
              <span className='amount'>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(
                  data.moreInfo.shouldSpend > 0 ? data.moreInfo.shouldSpend : 0
                )}
              </span>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <span>Thực tế chi tiêu hàng ngày</span>
              <span className='amount'>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(data.moreInfo.actualSpend)}
              </span>
            </div>
            <div className='d-flex justify-content-between mb-2j'>
              <span>Dự kiến chi tiêu</span>
              <span className='amount'>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(data.moreInfo.estimateSpend)}
              </span>
            </div>
          </div>
          <button className='login-button delete-button' onClick={handleDelete}>
            XÓA
          </button>
        </div>
      )}
    </div>
  );
};

export default BudgetDetail;
