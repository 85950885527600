import React, { useEffect, useState } from 'react';
import '../../css/view/home/HomePage.css';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/reducer/authSlice';
import { FaAngleDown, FaAngleUp, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {
  getCategory,
  getObject,
  getSurplusAmount,
  getTransaction,
  setFilterDateType,
  setSelectedTransaction,
  setTransactionType,
  transactionSelector,
  updateTransactionEditMode,
} from '../../store/reducer/transactionSlice';
import { getGeneralReportService } from '../../services/report.service';
import {
  EnumEditMode,
  EnumFilterDateType,
  EnumTransactionType,
} from '../../config';
import commonFunction from '../../common/commonFunction';
import { BarChartCustom, PieChartCustom } from '../../components';

const HomePage = () => {
  const { user } = useSelector(authSelector);
  const { surplusAmount, filterDateType } = useSelector(transactionSelector);
  const dispatch = useDispatch();
  const [isShowSurplus, setIsShowSurplus] = useState(false);
  const [barChartData, setBarChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [isLoadingGeneral, setIsLoadingGeneral] = useState(true);
  const [filterTime, setFilterTime] = useState(null);
  const navigate = useNavigate();

  // Lấy dữ liệu cho chart
  const getGeneralReport = async () => {
    const res = await getGeneralReportService(filterTime);
    if (res && res.success) {
      if (res.data.receiveAmount || res.data.payAmount) {
        setBarChartData([
          {
            value: res.data.receiveAmount / 1,
            itemStyle: {
              color: '#02c39a',
            },
          },
          {
            value: res.data.payAmount / 1,
            itemStyle: {
              color: '#ff4d6d',
            },
          },
        ]);
      } else {
        setBarChartData(null);
      }

      let details = res.data.lstPayment;
      let pieData = [];
      if (details && details.length > 0) {
        details.forEach((pay) => {
          pieData.push({
            value: pay.amount,
            name: pay.category[0].description + ` (${pay.percent}%)`,
          });
        });
        setPieChartData(pieData);
      } else {
        setPieChartData(null);
      }

      setCurrentTransaction(res.data.currentTransaction);
    }
    setIsLoadingGeneral(false);
  };

  // Các hàm gọi khi load màn hình
  useEffect(() => {
    setFilterTime(commonFunction.getFilterDate(filterDateType));
    dispatch(getSurplusAmount());
    dispatch(getTransaction());
  }, [dispatch, filterDateType]);

  // Các hàm gọi khi filterTime thay đổi
  useEffect(() => {
    getGeneralReport();
  }, [filterTime]);

  // Thay đổi lựa chọn filter
  const handleChangeFilterTime = (e) => {
    setFilterTime(commonFunction.getFilterDate(parseInt(e.target.value)));
    dispatch(setFilterDateType(parseInt(e.target.value)));
  };

  return (
    <div id='home-page' className='p-3'>
      <div className='p-3 home-header mb-3'>
        <div className='home-welcome mb-2'>
          Chào {user.fullName ? user.fullName : user.username}!
        </div>
        <div
          className='home-balance'
          onClick={() => setIsShowSurplus((prev) => !prev)}
        >
          {isShowSurplus ? (
            <>
              Ẩn số dư <FaAngleUp />
            </>
          ) : (
            <>
              Xem số dư <FaAngleDown />
            </>
          )}
        </div>
        {isShowSurplus ? (
          <div className='mt-2'>
            <div className='home-money p-2'>
              <span>Số dư</span>
              <p>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(surplusAmount)}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <div className='situation'>
        <div className='position-relative situation-chart flex-column align-items-center'>
          {!isLoadingGeneral ? (
            <>
              <div className='col-12 mt-2 mb-3'>
                <div className='mb-1 d-flex justify-content-between'>
                  <div className='situation-header mb-1'>Tình hình thu chi</div>
                  <select
                    className='select-filter-date mb-1'
                    defaultValue={filterDateType}
                    value={filterDateType}
                    onChange={handleChangeFilterTime}
                  >
                    <option value={EnumFilterDateType.today}>Hôm nay</option>
                    <option value={EnumFilterDateType.yesterday}>
                      Hôm qua
                    </option>
                    <option value={EnumFilterDateType.week}>Tuần này</option>
                    <option value={EnumFilterDateType.lastWeek}>
                      Tuần trước
                    </option>
                    <option value={EnumFilterDateType.month}>Tháng này</option>
                    <option value={EnumFilterDateType.lastMonth}>
                      Tháng trước
                    </option>
                    <option value={EnumFilterDateType.quarter}>Quý này</option>
                    <option value={EnumFilterDateType.lastQuarter}>
                      Quý truớc
                    </option>
                    <option value={EnumFilterDateType.year}>Năm nay</option>
                    <option value={EnumFilterDateType.lastYear}>
                      Năm trước
                    </option>
                    <option value={EnumFilterDateType.jan}>Tháng 1</option>
                    <option value={EnumFilterDateType.feb}>Tháng 2</option>
                    <option value={EnumFilterDateType.mar}>Tháng 3</option>
                    <option value={EnumFilterDateType.apr}>Tháng 4</option>
                    <option value={EnumFilterDateType.may}>Tháng 5</option>
                    <option value={EnumFilterDateType.jun}>Tháng 6</option>
                    <option value={EnumFilterDateType.jul}>Tháng 7</option>
                    <option value={EnumFilterDateType.aug}>Tháng 8</option>
                    <option value={EnumFilterDateType.sep}>Tháng 9</option>
                    <option value={EnumFilterDateType.oct}>Tháng 10</option>
                    <option value={EnumFilterDateType.nov}>Tháng 11</option>
                    <option value={EnumFilterDateType.dec}>Tháng 12</option>
                  </select>
                </div>
                <div className='loan-amount p-3'>
                  {barChartData ? (
                    <BarChartCustom
                      data={barChartData}
                      xlabels={['Thu', 'Chi']}
                      yUnit={'Số tiền (VND)'}
                    />
                  ) : (
                    <div className='text-center'>Chưa có ghi chép nào</div>
                  )}
                </div>
              </div>
              <div className='col-12 mt-2 mb-3'>
                <div className='mb-1 d-flex justify-content-between'>
                  <div className='situation-header mb-1'>Các khoản chi</div>
                  <select
                    className='select-filter-date mb-1'
                    defaultValue={filterDateType}
                    value={filterDateType}
                    onChange={handleChangeFilterTime}
                  >
                    <option value={EnumFilterDateType.today}>Hôm nay</option>
                    <option value={EnumFilterDateType.yesterday}>
                      Hôm qua
                    </option>
                    <option value={EnumFilterDateType.week}>Tuần này</option>
                    <option value={EnumFilterDateType.lastWeek}>
                      Tuần trước
                    </option>
                    <option value={EnumFilterDateType.month}>Tháng này</option>
                    <option value={EnumFilterDateType.lastMonth}>
                      Tháng trước
                    </option>
                    <option value={EnumFilterDateType.quarter}>Quý này</option>
                    <option value={EnumFilterDateType.lastQuarter}>
                      Quý truớc
                    </option>
                    <option value={EnumFilterDateType.year}>Năm nay</option>
                    <option value={EnumFilterDateType.lastYear}>
                      Năm trước
                    </option>
                    <option value={EnumFilterDateType.jan}>Tháng 1</option>
                    <option value={EnumFilterDateType.feb}>Tháng 2</option>
                    <option value={EnumFilterDateType.mar}>Tháng 3</option>
                    <option value={EnumFilterDateType.apr}>Tháng 4</option>
                    <option value={EnumFilterDateType.may}>Tháng 5</option>
                    <option value={EnumFilterDateType.jun}>Tháng 6</option>
                    <option value={EnumFilterDateType.jul}>Tháng 7</option>
                    <option value={EnumFilterDateType.aug}>Tháng 8</option>
                    <option value={EnumFilterDateType.sep}>Tháng 9</option>
                    <option value={EnumFilterDateType.oct}>Tháng 10</option>
                    <option value={EnumFilterDateType.nov}>Tháng 11</option>
                    <option value={EnumFilterDateType.dec}>Tháng 12</option>
                  </select>
                </div>
                <div className='loan-amount p-3'>
                  {pieChartData ? (
                    <PieChartCustom data={pieChartData} chartName='Khoản chi' />
                  ) : (
                    <div className='text-center'>Chưa có ghi chép nào</div>
                  )}
                </div>
              </div>
              <div className='col-12 mt-2 mb-3'>
                <div className='mb-1 d-flex justify-content-between'>
                  <div className='situation-header mb-1'>Giao dịch gần đây</div>
                  <div className='show-detail'>
                    <Link to='/transaction/detail'>Xem tất cả</Link>
                  </div>
                </div>
                <div className='loan-amount p-3'>
                  {currentTransaction && currentTransaction.length > 0 ? (
                    <>
                      {currentTransaction.map((tran, j) => {
                        return (
                          <div
                            className='d-flex justify-content-between pt-2 pb-2 align-items-center'
                            key={j}
                          >
                            <div className='d-flex align-items-center'>
                              <img
                                className='me-2'
                                src={tran.categoryID.image}
                                height={40}
                                width={40}
                                alt='category'
                              />
                              <div className='d-flex flex-column'>
                                <span className='trans-cate'>
                                  {tran.categoryID.description}
                                </span>
                                <span className='trans-desc'>
                                  <i>{tran.description}</i>
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                tran.transactionType ===
                                  EnumTransactionType.pay ||
                                tran.transactionType ===
                                  EnumTransactionType.loan ||
                                tran.transactionType ===
                                  EnumTransactionType.repayment
                                  ? 'pay-amount'
                                  : 'receive-amount'
                              }
                            >
                              {new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND',
                              }).format(tran.amount)}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className='text-center'>Chưa có ghi chép nào</div>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <button
        className='btn-add'
        onClick={() => {
          dispatch(setSelectedTransaction(null));
          dispatch(updateTransactionEditMode(EnumEditMode.add));
          dispatch(setTransactionType(EnumTransactionType.pay));
          navigate('/transaction');
        }}
      >
        <FaPlus />
      </button>
    </div>
  );
};

export default HomePage;
