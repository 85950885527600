import React, { useEffect, useState } from 'react';
import '../../css/view/debit/DebtPage.css';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import {
  setSelectedTransaction,
  setTransactionType,
  updateTransactionEditMode,
} from '../../store/reducer/transactionSlice';
import { EnumDebtTab, EnumEditMode, EnumTransactionType } from '../../config';
import { useNavigate } from 'react-router';
import { BaseLoading, BorrowTab, LoanTab } from '../../components';
import { getDebtReportService } from '../../services/report.service';

const DebtPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(EnumDebtTab.loan);

  const getData = async () => {
    const res = await getDebtReportService();
    if (res && res.success) {
      setReportData(res.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <div id='debt-page'>
      <div className='frm-header p-3 justify-content-center'>
        <div className='frm-title'>Theo dõi vay nợ</div>
      </div>
      {reportData ? (
        <div className='debt-content d-flex flex-column ps-3 pe-3'>
          <div className='d-flex justify-content-between'>
            <div
              className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
                tab === EnumDebtTab.loan ? 'active' : null
              }`}
              onClick={() => setTab(EnumDebtTab.loan)}
            >
              CHO VAY
            </div>
            <div
              className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
                tab === EnumDebtTab.borrow ? 'active' : null
              }`}
              onClick={() => setTab(EnumDebtTab.borrow)}
            >
              CÒN NỢ
            </div>
          </div>
          {tab === EnumDebtTab.loan && (
            <LoanTab
              data={reportData}
              navigate={navigate}
              dispatch={dispatch}
            />
          )}
          {tab === EnumDebtTab.borrow && (
            <BorrowTab
              data={reportData}
              navigate={navigate}
              dispatch={dispatch}
            />
          )}
        </div>
      ) : null}
      {isLoading ? <BaseLoading /> : null}
      <button
        className='btn-add'
        onClick={() => {
          dispatch(setSelectedTransaction(null));
          dispatch(updateTransactionEditMode(EnumEditMode.add));
          dispatch(setTransactionType(EnumTransactionType.loan));
          navigate('/debt/add');
        }}
      >
        <FaPlus />
      </button>
    </div>
  );
};

export default DebtPage;
