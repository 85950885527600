import React, { useState } from 'react';
import '../../css/component/debt/LoanTab.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { setDetailDebt } from '../../store/reducer/transactionSlice';
import { EnumTransactionType } from '../../config';

const BorrowTab = ({ data, navigate, dispatch }) => {
  const [isShowLoan, setIsShowLoan] = useState(true);
  const [isShowDone, setIsShowDone] = useState(false);

  return (
    <div id='loan-tab'>
      {data.borrowAmount > 0 ? (
        <>
          {' '}
          <div className='loan-amount mt-3 mb-3 p-3'>
            <div className='mb-3'>
              Còn phải trả:{' '}
              <span className='pay-amount'>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(data.borrowAmount - data.repaymentAmount)}
              </span>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <div className='d-flex flex-column'>
                <span>Đã trả</span>
                <span className='receive-amount'>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(data.repaymentAmount)}
                </span>
              </div>
              <div className='d-flex flex-column align-items-end'>
                <span>Tổng tiền vay</span>
                <span className='amount'>
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(data.borrowAmount)}
                </span>
              </div>
            </div>
            <div className='loan-bar'>
              <div
                className='loan-bar-done'
                style={{
                  width: `${
                    data && data.borrowAmount
                      ? (data.repaymentAmount / data.borrowAmount) * 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>
          </div>
          <div className='loan-detail mb-3'>
            <div
              className='loan-detail-title d-flex justify-content-between align-items-center p-3'
              onClick={() => setIsShowLoan((prev) => !prev)}
            >
              <span>Đang theo dõi</span>
              {isShowLoan ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {data.lstObject && isShowLoan
              ? data.lstObject
                  .filter(
                    (item) =>
                      item.borrowAmount > 0 &&
                      item.borrowAmount > item.repaymentAmount
                  )
                  .map((item) => {
                    return (
                      <div
                        key={item.objectID}
                        className='loan-detail-item d-flex justify-content-between align-items-center ps-3 pe-3 pt-2 pb-2'
                        onClick={() => {
                          dispatch(
                            setDetailDebt({
                              objectID: item.objectID,
                              type: EnumTransactionType.borrow,
                            })
                          );
                          navigate('/report/debt-detail');
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <div className='object-report-name me-2'>
                            {item.objectName.slice(0, 1)}
                          </div>
                          <div className='object-name'>
                            <span>{item.objectName}</span>
                          </div>
                        </div>
                        <div className='pay-amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(item.borrowAmount - item.repaymentAmount)}
                        </div>
                      </div>
                    );
                  })
              : null}
          </div>
          <div className='loan-detail mb-3'>
            <div
              className='loan-detail-title d-flex justify-content-between align-items-center p-3'
              onClick={() => setIsShowDone((prev) => !prev)}
            >
              <span>Đã hoàn thành</span>
              {isShowDone ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {data.lstObject && isShowDone
              ? data.lstObject
                  .filter(
                    (item) =>
                      item.borrowAmount > 0 &&
                      item.borrowAmount === item.repaymentAmount
                  )
                  .map((item) => {
                    return (
                      <div
                        key={item.objectID}
                        className='loan-detail-item d-flex justify-content-between align-items-center ps-3 pe-3 pt-2 pb-2'
                        onClick={() => {
                          dispatch(
                            setDetailDebt({
                              objectID: item.objectID,
                              type: EnumTransactionType.borrow,
                            })
                          );
                          navigate('/report/debt-detail');
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <div className='object-report-name me-2'>
                            {item.objectName.slice(0, 1)}
                          </div>
                          <div className='object-name'>
                            <span>{item.objectName}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
              : null}
          </div>
        </>
      ) : (
        <div className='no-loan d-flex align-items-center justify-content-center'>
          Chưa có khoản vay nào
        </div>
      )}
    </div>
  );
};

export default BorrowTab;
