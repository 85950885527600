import React from 'react';

const MonthDetail = ({ detail, onClick }) => {
  return (
    <div
      className='month-detail d-flex justify-content-between mb-2 p-3'
      onClick={onClick}
    >
      <div>
        <div className='month-name'>{detail.title}</div>
        {detail.description ? (
          <div className='month-desc'>{detail.description}</div>
        ) : null}
      </div>
      <div className='d-flex flex-column align-items-end'>
        <span className='receive-amount'>
          {new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
          }).format(detail.receiveAmount ? detail.receiveAmount : 0)}
        </span>
        <span className='pay-amount'>
          {new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
          }).format(detail.payAmount ? detail.payAmount : 0)}
        </span>
        <span className='surplus-amount'>
          {new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
          }).format(
            (detail.receiveAmount ? detail.receiveAmount : 0) -
              (detail.payAmount ? detail.payAmount : 0)
          )}
        </span>
      </div>
    </div>
  );
};

export default MonthDetail;
