import React from 'react';
import '../../css/component/base/BaseLoading.css';

const BaseLoading = ({ isLoadFull }) => {
  return (
    <div className={isLoadFull ? 'loading-bg-full' : 'loading-bg'}>
      <div className='loading'>
        <img
          className='loading-image'
          src='/images/loading.gif'
          alt='Loading'
        />
      </div>
    </div>
  );
};

export default BaseLoading;
