import React from 'react';
import '../../css/layout/DefaultLayout.css';
import BottomBar from './BottomBar';
import Sidebar from './Sidebar';

const DefaultLayout = ({ children }) => {
  return (
    <div className='default-layout'>
      <div className='d-flex layout-container'>
        <div className='d-none d-md-block'>
          <Sidebar />
        </div>
        <div className='layout-content'>{children}</div>
      </div>
      <div className='layout-bottom d-md-none'>
        <BottomBar />
      </div>
    </div>
  );
};

export default DefaultLayout;
