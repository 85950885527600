import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCategoryService,
  getTransactionService,
} from '../../services/transaction.service';
import { getSurplusService } from '../../services/report.service';
import {
  EnumEditMode,
  EnumFilterDateType,
  EnumTransactionType,
} from '../../config';
import { getObjectService } from '../../services/object.service';

export const getCategory = createAsyncThunk(
  'transaction/getCategory',
  async (rejectWithValue) => {
    const res = await getCategoryService();
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue();
    }
  }
);

export const getObject = createAsyncThunk(
  'transaction/getObject',
  async (rejectWithValue) => {
    const res = await getObjectService();
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue();
    }
  }
);

export const getTransaction = createAsyncThunk(
  'transaction/getTransaction',
  async (rejectWithValue) => {
    const res = await getTransactionService();
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue();
    }
  }
);

export const getSurplusAmount = createAsyncThunk(
  'transaction/getSurplusAmount',
  async (rejectWithValue) => {
    const res = await getSurplusService();
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue();
    }
  }
);

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    lstCategory: [],
    lstTransaction: [],
    surplusAmount: 0,
    transactionEditMode: EnumEditMode.add,
    selectedTransaction: null,
    filterDateType: EnumFilterDateType.month,
    transactionType: EnumTransactionType.pay,
    lstObject: [],
    detailDate: null,
    detailCategory: null,
    detailDebt: null,
    selectedBudget: null,
    revenueExpenditureDetailTime: null,
  },
  reducers: {
    addTransaction(state, action) {
      state.lstTransaction.unshift(action.payload);
      state.lstTransaction = state.lstTransaction.sort(
        (a, b) => new Date(b.refDate) - new Date(a.refDate)
      );
    },
    updateTransactionEditMode(state, action) {
      state.transactionEditMode = action.payload;
    },
    setSelectedTransaction(state, action) {
      state.selectedTransaction = action.payload;
    },
    editTransaction(state, action) {
      state.lstTransaction = state.lstTransaction.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
    },
    deleteTransaction(state, action) {
      state.lstTransaction = state.lstTransaction.filter(
        (item) => item._id !== action.payload
      );
    },
    setFilterDateType(state, action) {
      state.filterDateType = parseInt(action.payload);
    },
    setTransactionType(state, action) {
      state.transactionType = parseInt(action.payload);
    },
    addObject(state, action) {
      state.lstObject.push(action.payload);
    },
    setDetailDate(state, action) {
      state.detailDate = action.payload;
    },
    setDetailCategory(state, action) {
      state.detailCategory = action.payload;
    },
    setDetailDebt(state, action) {
      state.detailDebt = action.payload;
    },
    setSelectedBudget(state, action) {
      state.selectedBudget = action.payload;
    },
    setRevenueExpenditureDetailTime(state, action) {
      state.revenueExpenditureDetailTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.lstCategory = action.payload;
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.lstTransaction = action.payload;
    });
    builder.addCase(getSurplusAmount.fulfilled, (state, action) => {
      state.surplusAmount = action.payload;
    });
    builder.addCase(getObject.fulfilled, (state, action) => {
      state.lstObject = action.payload;
    });
  },
});

// Reducer
const transactionReducer = transactionSlice.reducer;

// Actions
export const {
  addTransaction,
  updateTransactionEditMode,
  setSelectedTransaction,
  editTransaction,
  deleteTransaction,
  setFilterDateType,
  setTransactionType,
  addObject,
  setDetailDate,
  setDetailCategory,
  setDetailDebt,
  setSelectedBudget,
  setRevenueExpenditureDetailTime,
} = transactionSlice.actions;

// Selector
export const transactionSelector = (state) => state.transactionReducer;

// Export reducer
export default transactionReducer;
