import React from 'react';
import '../../css/component/report/ReportItem.css';

const ReportItem = ({ content, icon, customClass, color, onClick }) => {
  return (
    <div
      id='report-item'
      className={`d-flex flex-column align-items-center justify-content-start ps-3 pe-3 pt-4 pb-4 ${customClass}`}
      onClick={onClick}
    >
      <span className='report-icon' style={{ color: color }}>
        {icon}
      </span>
      <span className='report-name'>{content}</span>
    </div>
  );
};

export default ReportItem;
