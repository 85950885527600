import React, { memo, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const BarNegativeChartCustom = ({
  data1,
  data2,
  caption1,
  caption2,
  xUnit,
  xLabels,
  yUnit,
}) => {
  const chartRef = useRef();

  const [customOption, setCustomOption] = useState(null);

  useEffect(() => {
    setCustomOption({
      legend: {
        data: [caption1, caption2],
      },
      tooltip: { trigger: 'item' },
      xAxis: {
        data: xLabels,
        type: 'category',
        name: xUnit,
        nameLocation: 'center',
        nameGap: 32,
      },
      yAxis: { type: 'value', name: yUnit },
      grid: { left: '2%', right: '2%', containLabel: true },
      series: [
        {
          name: caption1,
          type: 'bar',
          stack: 'one',
          itemStyle: {
            borderRadius: 6,
            color: '#02c39a',
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: data1,
        },
        {
          name: caption2,
          type: 'bar',
          stack: 'one',
          itemStyle: {
            borderRadius: 6,
            color: '#ff4d6d',
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: data2,
        },
      ],
    });
  }, [data1, data2, xLabels, xUnit, caption1, caption2, yUnit]);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    if (customOption) {
      chart.setOption(customOption);
    }
  }, [customOption]);

  return (
    <div
      className='bar-brush-chart'
      ref={chartRef}
      style={{ width: '100%', minHeight: '400px' }}
    ></div>
  );
};

export default memo(BarNegativeChartCustom);
