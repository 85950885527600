import React, { useEffect, useState } from 'react';
import '../../css/view/LoginPage.css';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  checkPasscode,
  loginService,
  registerService,
} from '../../services/auth.service';
import setAuthToken from '../../utils/setAuthToken';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, updateUserInfo } from '../../store/reducer/authSlice';
import constants from '../../config/constants';

const LoginPage = ({ path }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [loginData, setLoginData] = useState({
    username: localStorage.getItem(constants.USERNAME)
      ? localStorage.getItem(constants.USERNAME)
      : '',
    password: '',
    cpassword: '',
  });
  const [disablePIN, setDisablePIN] = useState(false);

  useEffect(() => {
    if (path === '/login') {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [path]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Validate dữ liệu trước khi save
   * @param {boolean} isReg Có phải là đăng ký k
   * @returns
   */
  const validateBeforeSave = (isReg) => {
    if (String.isNullOrWhiteSpace(loginData.username)) {
      alert('Tên đăng nhập không được để trống');
      return false;
    }
    if (String.isNullOrWhiteSpace(loginData.password)) {
      alert('Mật khẩu không được để trống');
      return false;
    }
    if (isReg) {
      if (loginData.password !== loginData.cpassword) {
        alert('Xác nhận mật khẩu không trùng khớp');
        return false;
      }
    }
    return true;
  };

  /**
   * Xử lý đăng nhập
   */
  const handleLogin = async () => {
    if (validateBeforeSave(false)) {
      const res = await loginService(loginData);
      if (!res.success) {
        alert(res.message);
      } else {
        localStorage.setItem(constants.TOKEN_NAME, res.data.token);
        localStorage.setItem(constants.USERNAME, loginData.username);
        setAuthToken(res.data.token);
        dispatch(updateUserInfo(res.data.user));
        navigate('/');
      }
    }
  };

  /**
   * Xử lý đăng ký
   */
  const handleRegister = async () => {
    if (validateBeforeSave(true)) {
      const res = await registerService(loginData);
      if (!res.success) {
        alert(res.message);
      } else {
        localStorage.setItem(constants.TOKEN_NAME, res.data.token);
        setAuthToken(res.data.token);
        dispatch(updateUserInfo(res.data.user));
        navigate('/info');
      }
    }
  };

  /**
   * Thay đổi textInput
   * @param {envent} e
   */
  const handleChangeLoginInput = (e) => {
    setLoginData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    if (e.target.name === 'username') {
      setDisablePIN(false);
    }
  };

  const { isAuthenticated } = useSelector(authSelector);

  const handleLoginWithPIN = async () => {
    const res = await checkPasscode({ username: loginData.username });
    if (res.success && res.data) {
      localStorage.setItem(constants.USERNAME, loginData.username);
      navigate('/login-pin');
    } else {
      alert('Bạn chưa thiết lập mã PIN');
      setDisablePIN(true);
    }
  };
  return isAuthenticated ? (
    <Navigate to='/' />
  ) : (
    <div id='login-page' className='container'>
      <div className='login-header flex-md-row'>
        {/* Header */}
        <img className='login-page-logo' src='/images/logo.png' alt='logo' />
        {/* Body */}
        <div className='login-input-group' style={{ maxWidth: 560 }}>
          {isLogin ? (
            <>
              <input
                className='login-input'
                type='text'
                name='username'
                placeholder='Tên đăng nhập'
                value={loginData.username}
                onChange={(e) => handleChangeLoginInput(e)}
              />
              <input
                className='login-input'
                type='password'
                name='password'
                placeholder='Mật khẩu'
                value={loginData.password}
                onChange={(e) => handleChangeLoginInput(e)}
              />
              <button className='login-button' onClick={handleLogin}>
                ĐĂNG NHẬP
              </button>
              <div className='register-group'>
                <div>Bạn chưa có tài khoản?</div>
                <Link to='/register' className='register-link'>
                  ĐĂNG KÝ
                </Link>
              </div>
            </>
          ) : (
            <>
              <input
                className='login-input'
                type='text'
                name='username'
                placeholder='Tên đăng nhập'
                value={loginData.username}
                onChange={(e) => handleChangeLoginInput(e)}
              />
              <input
                className='login-input'
                type='password'
                name='password'
                placeholder='Mật khẩu'
                value={loginData.password}
                onChange={(e) => handleChangeLoginInput(e)}
              />
              <input
                className='login-input'
                type='password'
                name='cpassword'
                placeholder='Xác nhận mật khẩu'
                value={loginData.cpassword}
                onChange={(e) => handleChangeLoginInput(e)}
              />
              <button className='login-button' onClick={handleRegister}>
                TIẾP TỤC
              </button>
              <div className='register-group'>
                <div>Bạn đã có tài khoản?</div>
                <Link to='/login' className='register-link'>
                  ĐĂNG NHẬP
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Footer */}
      {isLogin ? (
        <div className='login-footer'>
          <button
            className='pin-link'
            onClick={handleLoginWithPIN}
            disabled={disablePIN}
          >
            Đăng nhập bằng mã PIN
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default LoginPage;
