import axios from 'axios';
import constants from '../config/constants';

export const getCategoryService = async () => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/categories`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTransactionService = async () => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/transactions`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addTransactionService = async (data) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/transactions`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editTransactionService = async (data) => {
  try {
    const res = await axios.put(
      `${constants.API_URL}/api/${constants.API_VER}/ma/transactions`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTransactionService = async (id) => {
  try {
    const res = await axios.delete(
      `${constants.API_URL}/api/${constants.API_VER}/ma/transactions/${id}`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addDebtService = async (data) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/transactions/debt`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAmountByBudget = async (id) => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/transactions/budget/${id}`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
