import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../store/reducer/transactionSlice';

const SelectDescription = ({ categoryID, onBackClick, onSelect }) => {
  const { lstTransaction } = useSelector(transactionSelector);
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [lstDesc, setLstDesc] = useState([]);

  const filterDescription = () => {
    if (lstTransaction && categoryID) {
      const lstFilter = lstTransaction
        .filter((item) => item.categoryID._id === categoryID)
        .map((item) => item.description);
      setLstDesc([...new Set(lstFilter.map((item) => item.toLowerCase()))]);
    }
  };

  useEffect(() => {
    filterDescription();
  }, [categoryID]);

  return (
    <div id='select-category' className='p-3'>
      {/* Thanh header */}
      <div className='frm-header mb-3'>
        <FaChevronLeft onClick={onBackClick} />
        <div className='frm-title'>Chọn diễn giải</div>
        <FaSearch onClick={() => setIsShowSearchBar((prev) => !prev)} />
      </div>
      {/* Thanh tìm kiếm */}
      {isShowSearchBar ? (
        <div className='search-wrap mb-3'>
          <input
            className='search-bar'
            placeholder='Tìm kiếm diễn giải'
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
          <FaSearch className='search-icon' />
        </div>
      ) : null}
      {/* Danh sách hạng mục */}
      <div className='lst-category'>
        {lstDesc
          ? lstDesc
              .filter((item) =>
                item.toLowerCase().includes(textSearch.toLowerCase())
              )
              .map((item, i) => (
                <div
                  className='category-item mb-2'
                  key={i}
                  onClick={() => onSelect(item)}
                >
                  <div className='px-2 py-1'>{item}</div>
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

export default SelectDescription;
