import axios from 'axios';
import constants from '../config/constants';

export const getAllBudgetService = async () => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/budget`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBudgetByIDService = async (id) => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/budget/${id}`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addBudgetService = async (data) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/budget`,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBudgetService = async (id) => {
  try {
    const res = await axios.delete(
      `${constants.API_URL}/api/${constants.API_VER}/ma/budget/${id}`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
