import React, { useEffect, useState } from 'react';
import '../../css/view/home/ListTransactionPage.css';
import { FaChevronLeft, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFilterDateType,
  setSelectedTransaction,
  transactionSelector,
  updateTransactionEditMode,
} from '../../store/reducer/transactionSlice';
import {
  EnumEditMode,
  EnumFilterDateType,
  EnumTransactionType,
} from '../../config';
import commonFunction from '../../common/commonFunction';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

const ListTransactionPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { lstTransaction, filterDateType } = useSelector(transactionSelector);
  const [filterTime, setFilterTime] = useState(null);
  const [amount, setAmount] = useState({
    payAmount: 0,
    receiveAmount: 0,
  });
  const [isShowTotal, setIsShowTotal] = useState(false);

  // Lọc dữ liệu
  const filterData = () => {
    let lstDate = [];
    let lstDetails = [];
    let payAmount = 0;
    let receiveAmount = 0;

    if (lstTransaction) {
      let lstTransactionFilter = filterTime
        ? lstTransaction.filter(
            (item) =>
              new Date(item.refDate) > new Date(filterTime.startTime) &&
              new Date(item.refDate) < new Date(filterTime.endTime)
          )
        : lstTransaction;
      lstTransactionFilter.forEach((item) => {
        const date = new Date(item.refDate);
        const dateString = commonFunction.getDDMMYYY(date);
        if (lstDate.includes(dateString)) {
          let detail = lstDetails.find((item) => item.date === dateString);
          if (detail) {
            if (
              item.transactionType === EnumTransactionType.pay ||
              item.transactionType === EnumTransactionType.loan ||
              item.transactionType === EnumTransactionType.repayment
            ) {
              detail.payAmount += item.amount;
            } else {
              detail.receiveAmount += item.amount;
            }
            detail.transaction.push(item);
          }
        } else {
          lstDetails.push({
            date: dateString,
            payAmount:
              item.transactionType === EnumTransactionType.pay ||
              item.transactionType === EnumTransactionType.loan ||
              item.transactionType === EnumTransactionType.repayment
                ? item.amount
                : 0,
            receiveAmount:
              item.transactionType === EnumTransactionType.pay ||
              item.transactionType === EnumTransactionType.loan ||
              item.transactionType === EnumTransactionType.repayment
                ? 0
                : item.amount,
            transaction: [item],
          });
          lstDate.push(dateString);
        }

        if (
          item.transactionType === EnumTransactionType.pay ||
          item.transactionType === EnumTransactionType.loan ||
          item.transactionType === EnumTransactionType.repayment
        ) {
          payAmount += item.amount;
        } else {
          receiveAmount += item.amount;
        }
      });
    }

    setData(lstDetails);
    setAmount({
      payAmount: payAmount,
      receiveAmount: receiveAmount,
    });
  };

  useEffect(() => {
    filterData();
  }, [lstTransaction, filterTime]);

  useEffect(() => {
    setFilterTime(commonFunction.getFilterDate(filterDateType));
  }, [filterDateType]);

  const dispatch = useDispatch();

  // Sửa giao dịch
  const editTransaction = (item) => {
    dispatch(setSelectedTransaction(item));
    dispatch(updateTransactionEditMode(EnumEditMode.edit));
    navigate('/transaction');
  };

  // Thay đổi lựa chọn filter
  const handleChangeFilterTime = (e) => {
    setFilterTime(commonFunction.getFilterDate(parseInt(e.target.value)));
    dispatch(setFilterDateType(parseInt(e.target.value)));
  };

  return (
    <div id='list-transaction'>
      <div className='frm-top'>
        <div className='frm-header p-3 justify-content-md-center'>
          <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
          <div className='frm-title'>Chi tiết thu chi</div>
          <FaPlus
            className='d-md-none'
            onClick={() => {
              dispatch(setSelectedTransaction(null));
              dispatch(updateTransactionEditMode(EnumEditMode.add));
              navigate('/transaction');
            }}
          />
        </div>
        <div className='mt-2 mb-2 ps-md-0 pe-md-0 ps-2 pe-2 d-flex justify-content-between'>
          <div className='situation-header mb-1'>
            {filterTime
              ? commonFunction.getDDMMYYY(filterTime.startTime) +
                ' - ' +
                commonFunction.getDDMMYYY(filterTime.endTime)
              : null}
          </div>
          <select
            className='select-filter-date mb-1'
            defaultValue={filterDateType}
            onChange={handleChangeFilterTime}
          >
            <option value={EnumFilterDateType.today}>Hôm nay</option>
            <option value={EnumFilterDateType.yesterday}>Hôm qua</option>
            <option value={EnumFilterDateType.week}>Tuần này</option>
            <option value={EnumFilterDateType.lastWeek}>Tuần trước</option>
            <option value={EnumFilterDateType.month}>Tháng này</option>
            <option value={EnumFilterDateType.lastMonth}>Tháng trước</option>
            <option value={EnumFilterDateType.quarter}>Quý này</option>
            <option value={EnumFilterDateType.lastQuarter}>Quý truớc</option>
            <option value={EnumFilterDateType.year}>Năm nay</option>
            <option value={EnumFilterDateType.lastYear}>Năm trước</option>
            <option value={EnumFilterDateType.jan}>Tháng 1</option>
            <option value={EnumFilterDateType.feb}>Tháng 2</option>
            <option value={EnumFilterDateType.mar}>Tháng 3</option>
            <option value={EnumFilterDateType.apr}>Tháng 4</option>
            <option value={EnumFilterDateType.may}>Tháng 5</option>
            <option value={EnumFilterDateType.jun}>Tháng 6</option>
            <option value={EnumFilterDateType.jul}>Tháng 7</option>
            <option value={EnumFilterDateType.aug}>Tháng 8</option>
            <option value={EnumFilterDateType.sep}>Tháng 9</option>
            <option value={EnumFilterDateType.oct}>Tháng 10</option>
            <option value={EnumFilterDateType.nov}>Tháng 11</option>
            <option value={EnumFilterDateType.dec}>Tháng 12</option>
          </select>
        </div>
        {isShowTotal ? (
          <>
            <div className='d-flex justify-content-between ps-3 pe-3 pb-2 align-items-center'>
              <div className='situation-header mb-1'>Tổng thu</div>
              <span className='receive-amount'>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(amount.receiveAmount)}
              </span>
            </div>
            <div className='d-flex justify-content-between ps-3 pe-3 pb-2 align-items-center'>
              <div className='situation-header mb-1'>Tổng chi</div>
              <span className='pay-amount'>
                {new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                }).format(amount.payAmount)}
              </span>
            </div>
          </>
        ) : null}
        <div
          className='trans-desc d-flex justify-content-center align-items-center mb-2 mt-1'
          onClick={() => setIsShowTotal((prev) => !prev)}
        >
          {isShowTotal ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      <div className='list-trans'>
        {data && data.length > 0
          ? data.map((item, i) => {
              return (
                <div className='trans-item mb-2 pb-3' key={i}>
                  <div className='trans-head d-flex justify-content-between align-items-center ps-3 pe-3 pt-1 pb-1'>
                    <h1>{item.date}</h1>
                    <div className='d-flex flex-column align-items-end'>
                      {item.receiveAmount > 0 ? (
                        <span className='receive-amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(item.receiveAmount)}
                        </span>
                      ) : null}
                      {item.payAmount > 0 ? (
                        <span className='pay-amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(item.payAmount)}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {item.transaction.map((tran, j) => {
                    return (
                      <div
                        className='d-flex justify-content-between ps-4 pe-3 pt-1 pb-2 align-items-center'
                        onClick={() => {
                          if (
                            tran.transactionType === EnumTransactionType.pay ||
                            tran.transactionType === EnumTransactionType.receive
                          ) {
                            editTransaction(tran);
                          }
                        }}
                        key={j}
                      >
                        <div className='d-flex align-items-center'>
                          <img
                            className='me-2'
                            src={tran.categoryID.image}
                            height={40}
                            width={40}
                            alt='category'
                          />
                          <div className='d-flex flex-column'>
                            <span className='trans-cate'>
                              {tran.categoryID.description}
                            </span>
                            <span className='trans-desc'>
                              <i>{tran.description}</i>
                            </span>
                          </div>
                        </div>
                        <div
                          className={
                            tran.transactionType === EnumTransactionType.pay ||
                            tran.transactionType === EnumTransactionType.loan ||
                            tran.transactionType ===
                              EnumTransactionType.repayment
                              ? 'pay-amount'
                              : 'receive-amount'
                          }
                        >
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(tran.amount)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ListTransactionPage;
