import './css/app.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { authRoutes, routes } from './routes/routes';
import ProtectedRoute from './routes/ProtectedRoute';
import './extensions/string.extension';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, getUser } from './store/reducer/authSlice';
import { useEffect } from 'react';
import { BaseLoading } from './components/base';
import { DefaultLayout } from './views';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const { isLoading } = useSelector(authSelector);

  return isLoading ? (
    <BaseLoading isLoadFull={true} />
  ) : (
    <Router>
      <Routes>
        {authRoutes.map((route, index) => {
          const Page = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              element={<Page path={route.path} />}
            />
          );
        })}
        <Route element={<ProtectedRoute />}>
          {routes.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <DefaultLayout>
                    <Page />
                  </DefaultLayout>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
