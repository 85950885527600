import { EnumFilterDateType } from '../config';

const getFilterDate = (filterType) => {
  const today = new Date();
  let startTime, endTime;
  switch (filterType) {
    case EnumFilterDateType.today:
      startTime = endTime = getStartTime(today);
      break;
    case EnumFilterDateType.yesterday:
      startTime = endTime = getStartTime(addDate(today, -1));
      break;
    case EnumFilterDateType.week:
      let dayOfWeek = today.getDay();
      if (dayOfWeek === 0) {
        dayOfWeek = 7;
      }
      startTime = getStartTime(addDate(today, 1 - dayOfWeek));
      endTime = getEndTime(addDate(startTime, 6));
      break;
    case EnumFilterDateType.month:
      startTime = getStartTime(addDate(today, 1 - today.getDate()));
      endTime = getStartTime(
        addDate(
          startTime,
          daysOfMonth(today.getMonth(), today.getFullYear()) - 1
        )
      );
      break;
    case EnumFilterDateType.quarter:
      startTime = new Date(
        today.getFullYear(),
        Math.floor(today.getMonth() / 3) * 3,
        1
      );
      endTime = new Date(
        today.getFullYear(),
        startTime.getMonth() + 2,
        daysOfMonth(startTime.getMonth() + 2, today.getFullYear())
      );
      break;
    case EnumFilterDateType.year:
      startTime = new Date(today.getFullYear(), 0, 1);
      endTime = new Date(today.getFullYear(), 11, 31);
      break;
    case EnumFilterDateType.lastWeek:
      let dayOfCurrWeek = today.getDay();
      if (dayOfCurrWeek === 0) {
        dayOfCurrWeek = 7;
      }
      startTime = getStartTime(addDate(today, 1 - dayOfCurrWeek - 7));
      endTime = getEndTime(addDate(startTime, 6));
      break;
    case EnumFilterDateType.lastMonth:
      let lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
      startTime = getStartTime(new Date(today.getFullYear(), lastMonth, 1));
      endTime = getStartTime(
        addDate(startTime, daysOfMonth(lastMonth, today.getFullYear()) - 1)
      );
      break;
    case EnumFilterDateType.lastQuarter:
      const curQuater = Math.floor(today.getMonth() / 3);
      startTime = new Date(
        today.getFullYear(),
        (curQuater === 0 ? 3 : curQuater - 1) * 3,
        1
      );
      endTime = new Date(
        today.getFullYear(),
        startTime.getMonth() + 2,
        daysOfMonth(startTime.getMonth() + 2, today.getFullYear())
      );
      break;
    case EnumFilterDateType.lastYear:
      startTime = new Date(today.getFullYear() - 1, 0, 1);
      endTime = new Date(today.getFullYear() - 1, 11, 31);
      break;
    case EnumFilterDateType.jan:
    case EnumFilterDateType.feb:
    case EnumFilterDateType.mar:
    case EnumFilterDateType.apr:
    case EnumFilterDateType.may:
    case EnumFilterDateType.jun:
    case EnumFilterDateType.jul:
    case EnumFilterDateType.aug:
    case EnumFilterDateType.sep:
    case EnumFilterDateType.oct:
    case EnumFilterDateType.nov:
    case EnumFilterDateType.dec:
      startTime = new Date(today.getFullYear(), filterType - 11, 1);
      endTime = getStartTime(
        addDate(
          startTime,
          daysOfMonth(filterType - 11, today.getFullYear()) - 1
        )
      );
      break;
    default:
      startTime = endTime = getStartTime(today);
      break;
  }
  endTime = getEndTime(endTime);
  return {
    startTime,
    endTime,
  };
};

const getStartTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

const getEndTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

const addDate = (date, added) => {
  var result = new Date(date);
  result.setDate(result.getDate() + added);
  return result;
};

const daysOfMonth = (month, year) => {
  const isLeap = (0 === year % 4 && 0 !== year % 100) || 0 === year % 400;
  return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

const getDDMMYYY = (date) => {
  return `${
    date.getDate().toString().length === 1
      ? '0' + date.getDate()
      : date.getDate()
  }/${
    (date.getMonth() + 1).toString().length === 1
      ? '0' + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString()
  }/${date.getFullYear()}`;
};

const getTimeByMonthAndQuarter = (month, quarter, year) => {
  if (month) {
    return {
      startTime: new Date(year, month, 1),
      endTime: new Date(year, month, daysOfMonth(month, year)),
    };
  }
  if (quarter) {
    return {
      startTime: new Date(year, quarter * 3, 1),
      endTime: new Date(
        year,
        quarter * 3 + 2,
        daysOfMonth(quarter * 3 + 2, year)
      ),
    };
  }
};

export default {
  getFilterDate,
  getDDMMYYY,
  getTimeByMonthAndQuarter,
};
