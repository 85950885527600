import React from 'react';
import { ReportItem } from '../../components';
import '../../css/view/report/ReportPage.css';
import { useNavigate } from 'react-router';
import {
  FaChartColumn,
  FaChartArea,
  FaChartPie,
  FaListCheck,
  FaListOl,
  FaMoneyBillWave,
} from 'react-icons/fa6';

const ReportPage = () => {
  const navigate = useNavigate();

  return (
    <div id='report-page'>
      <div className='frm-header p-3 justify-content-center'>
        <div className='frm-title'>Báo cáo chi tiêu</div>
      </div>
      <div className='report-list d-flex'>
        <div className='col-6 '>
          <ReportItem
            customClass='ms-3 me-2 mb-3'
            icon={<FaChartColumn />}
            content='Tài chính hiện tại'
            color='rgb(53, 162, 235)'
            onClick={() => navigate('/report/current-finances')}
          />
          <ReportItem
            customClass='ms-3 me-2 mb-3'
            icon={<FaListCheck />}
            content='Phân tích thu'
            color='#02c39a'
            onClick={() => navigate('/report/revenue-analysis')}
          />
          <ReportItem
            customClass='ms-3 me-2 mb-3'
            icon={<FaChartPie />}
            content='Thu chi theo hạng mục'
            color='#006d77'
            onClick={() => navigate('/report/categories')}
          />
        </div>
        <div className='col-6'>
          <ReportItem
            customClass='ms-2 me-3 mb-3'
            icon={<FaChartArea />}
            content='Tình hình thu chi'
            color='#5f0f40'
            onClick={() => navigate('/report/revenue-expenditure')}
          />
          <ReportItem
            customClass='ms-2 me-3 mb-3'
            icon={<FaListOl />}
            content='Phân tích chi tiêu'
            color='#ff4d6d'
            onClick={() => navigate('/report/spending-analysis')}
          />
          <ReportItem
            customClass='ms-2 me-3 mb-3'
            icon={<FaMoneyBillWave />}
            content='Theo dõi vay/nợ'
            color='#bc6c25'
            onClick={() => navigate('/report/debt')}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
