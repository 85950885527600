import React, { useEffect, useState } from 'react';
import { FaCalendar, FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { getRevenueExpenditureService } from '../../services/report.service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/view/report/ReportRevenueExpenditure.css';
import {
  BarNegativeChartCustom,
  BaseLoading,
  MonthDetail,
} from '../../components';
import commonFunction from '../../common/commonFunction';
import { useDispatch } from 'react-redux';
import { setRevenueExpenditureDetailTime } from '../../store/reducer/transactionSlice';

/**
 * Tình hình thu chi
 * @returns
 */
const ReportRevenueExpenditure = () => {
  const EnumTab = {
    month: 1,
    quarter: 2,
  };
  const navigate = useNavigate();
  const [year, setYear] = useState(2024);
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [tab, setTab] = useState(EnumTab.month);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [label, setLabel] = useState(null);

  const dispatch = useDispatch();

  // Lấy dữ liệu cho biểu đồ
  const getChartData = async () => {
    const res = await getRevenueExpenditureService(year);
    if (res && res.success) {
      if (res.data && res.data.length > 0) {
        setDetails(res.data);
      }
    }
    setIsLoading(false);
  };

  // Xủ lý data khi lấy dữ liệu, đổi tab
  const handleData = () => {
    if (details && details.length > 0) {
      const lstData1 =
        tab === EnumTab.month
          ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          : tab === EnumTab.quarter
          ? [0, 0, 0, 0]
          : null;
      const lstData2 =
        tab === EnumTab.month
          ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          : tab === EnumTab.quarter
          ? [0, 0, 0, 0]
          : null;

      let listTemp = [];
      for (let i = 0; i < details.length; i++) {
        const item = details[i];
        if (tab === EnumTab.month) {
          lstData1[i] =
            lstData1[i] +
            (item.receiveAmount ? item.receiveAmount : 0) / 1000000;
          lstData2[i] =
            lstData2[i] - (item.payAmount ? item.payAmount : 0) / 1000000;

          if (!listTemp[i]) {
            listTemp[i] = {
              title: `Tháng ${i + 1}`,
              payAmount: item.payAmount ? item.payAmount : 0,
              receiveAmount: item.receiveAmount ? item.receiveAmount : 0,
            };
          } else {
            listTemp[i] = {
              ...listTemp[i],
              payAmount:
                listTemp[i].payAmount + (item.payAmount ? item.payAmount : 0),
              receiveAmount:
                listTemp[i].receiveAmount +
                (item.receiveAmount ? item.receiveAmount : 0),
            };
          }
        } else if (tab === EnumTab.quarter) {
          lstData1[Math.floor(i / 3)] =
            lstData1[Math.floor(i / 3)] +
            (item.receiveAmount ? item.receiveAmount : 0) / 1000000;
          lstData2[Math.floor(i / 3)] =
            lstData2[Math.floor(i / 3)] -
            (item.payAmount ? item.payAmount : 0) / 1000000;

          if (!listTemp[Math.floor(i / 3)]) {
            listTemp[Math.floor(i / 3)] = {
              title: `Quý ${Math.floor(i / 3) + 1}`,
              payAmount: item.payAmount ? item.payAmount : 0,
              receiveAmount: item.receiveAmount ? item.receiveAmount : 0,
            };
          } else {
            listTemp[Math.floor(i / 3)] = {
              ...listTemp[Math.floor(i / 3)],
              payAmount:
                listTemp[Math.floor(i / 3)].payAmount +
                (item.payAmount ? item.payAmount : 0),
              receiveAmount:
                listTemp[Math.floor(i / 3)].receiveAmount +
                (item.receiveAmount ? item.receiveAmount : 0),
            };
          }
        }
      }
      const labels =
        tab === EnumTab.month
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : [1, 2, 3, 4];
      setData1(lstData1);
      setData2(lstData2);
      setLabel(labels);
      setList(listTemp);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getChartData();
  }, [year]);

  useEffect(() => {
    handleData();
  }, [details, tab]);

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const handleClickDetail = (index) => {
    if (tab === EnumTab.month) {
      dispatch(
        setRevenueExpenditureDetailTime(
          JSON.stringify(
            commonFunction.getTimeByMonthAndQuarter(index, null, year)
          )
        )
      );
    } else {
      dispatch(
        setRevenueExpenditureDetailTime(
          JSON.stringify(
            commonFunction.getTimeByMonthAndQuarter(null, index, year)
          )
        )
      );
    }
    navigate('/report/revenue-expenditure-detail');
  };

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>Tình hình thu chi</div>
      </div>
      <div className='d-flex justify-content-between ps-3 pe-3 '>
        <div
          className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
            tab === EnumTab.month ? 'active' : null
          }`}
          onClick={() => setTab(EnumTab.month)}
        >
          Tháng
        </div>
        <div
          className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
            tab === EnumTab.quarter ? 'active' : null
          }`}
          onClick={() => setTab(EnumTab.quarter)}
        >
          Quý
        </div>
      </div>
      {/* Chọn năm */}
      <div className='picker-wrapper d-flex align-items-center justify-content-center ps-3 pe-3 pt-2 pb-2'>
        <FaCalendar />
        <DatePicker
          className='pick-year'
          selected={new Date(year, 1, 1)}
          renderYearContent={renderYearContent}
          showYearPicker
          dateFormat='yyyy'
          onChange={(date) => setYear(date.getFullYear())}
          readOnly={isLoading}
        />
      </div>
      <div className='report-content p-3'>
        {isLoading ? (
          <BaseLoading />
        ) : (
          <>
            {/* Chart */}
            {data1 && data2 && label ? (
              <div className='loan-amount p-3 mb-3'>
                <BarNegativeChartCustom
                  data1={data1}
                  data2={data2}
                  xLabels={label}
                  xUnit={tab === EnumTab.month ? 'Tháng' : 'Quý'}
                  yUnit='Triệu VND'
                  caption1='Thu'
                  caption2='Chi'
                />
                <div className='d-flex justify-content-between'>
                  <div className='month-name'>Tổng</div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='receive-amount'>
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(
                        list.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.receiveAmount;
                        }, 0)
                      )}
                    </span>
                    <span className='pay-amount'>
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(
                        list.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.payAmount;
                        }, 0)
                      )}
                    </span>
                    <span className='surplus-amount'>
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(
                        list.reduce((accumulator, currentValue) => {
                          return (
                            accumulator +
                            currentValue.receiveAmount -
                            currentValue.payAmount
                          );
                        }, 0)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            {list ? (
              <div className='revenue-detail d-flex flex-column-reverse'>
                {list.map((item, i) => {
                  if (item.payAmount !== 0 || item.receiveAmount !== 0) {
                    return (
                      <MonthDetail
                        key={i}
                        detail={item}
                        onClick={() => handleClickDetail(i)}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportRevenueExpenditure;
