import React from 'react';
import { FaAngleRight } from 'react-icons/fa';

const DayPayDetail = ({ item, isRevenue, onClick }) => {
  return (
    <div
      className='d-flex ps-3 pe-3 pt-2 pb-2 align-items-center'
      onClick={onClick}
    >
      <div
        className='date-circle me-2'
        style={{
          backgroundColor: `rgb(${Math.round(Math.random() * 255)}, 
          ${Math.round(Math.random() * 255)}, 
          ${Math.round(Math.random() * 255)})`,
        }}
      >
        {item.refDate.slice(0, 2)}
      </div>
      <div className='day-detail'>
        <span>{item.refDate}</span>
        <span className={isRevenue ? 'receive-amount' : 'pay-amount'}>
          {new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
          }).format(item.amount)}
        </span>
      </div>
      <FaAngleRight className='ms-2 gray-icon' />
    </div>
  );
};

export default DayPayDetail;
