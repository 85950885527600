import React, { useState } from 'react';
import '../../css/component/transaction/SelectCategory.css';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../store/reducer/transactionSlice';
import { FaChevronLeft, FaSearch } from 'react-icons/fa';

const SelectCategory = ({ transactionType, onBackClick, onChangeCategory }) => {
  const { lstCategory } = useSelector(transactionSelector);
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  return (
    <div id='select-category' className='p-3'>
      {/* Thanh header */}
      <div className='frm-header mb-3'>
        <FaChevronLeft onClick={onBackClick} />
        <div className='frm-title'>Chọn hạng mục</div>
        <FaSearch onClick={() => setIsShowSearchBar((prev) => !prev)} />
      </div>
      {/* Thanh tìm kiếm */}
      {isShowSearchBar ? (
        <div className='search-wrap mb-3'>
          <input
            className='search-bar'
            placeholder='Tìm kiếm tên danh mục'
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
          <FaSearch className='search-icon' />
        </div>
      ) : null}
      {/* Danh sách hạng mục */}
      <div className='lst-category'>
        {lstCategory
          ? lstCategory
              .filter(
                (item) =>
                  item.type === transactionType &&
                  item.description
                    .toLowerCase()
                    .includes(textSearch.toLowerCase())
              )
              .map((item, i) => (
                <div
                  className='category-item mb-3'
                  key={i}
                  onClick={() => onChangeCategory(item)}
                >
                  <img src={item.image} alt='Icon' />
                  <div>{item.description}</div>
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

export default SelectCategory;
