// Loại giao dịch
export const EnumTransactionType = {
  pay: 1, // Chi tiền
  receive: 2, // Thu tiền
  loan: 3, // Cho vay
  repayment: 4, // Trả nợ
  borrow: 5, // Đi vay
  debtCollection: 6, // Thu nợ
};

// EditMode
export const EnumEditMode = {
  add: 1,
  edit: 2,
};

// Ngày lọc
export const EnumFilterDateType = {
  today: 0,
  yesterday: 1,
  week: 2,
  lastWeek: 6,
  month: 3,
  lastMonth: 7,
  quarter: 4,
  lastQuarter: 8,
  year: 5,
  lastYear: 10,
  other: 9,
  jan: 11,
  feb: 12,
  mar: 13,
  apr: 14,
  may: 15,
  jun: 16,
  jul: 17,
  aug: 18,
  sep: 19,
  oct: 20,
  nov: 21,
  dec: 22,
};

export const EnumDebtTab = {
  loan: 1,
  borrow: 2,
};
