import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { EnumFilterDateType, EnumTransactionType } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDetailCategory,
  setFilterDateType,
  transactionSelector,
} from '../../store/reducer/transactionSlice';
import { getCategoriesReportService } from '../../services/report.service';
import commonFunction from '../../common/commonFunction';
import '../../css/view/report/ReportByCategories.css';
import { BaseLoading, PieChartCustom } from '../../components';

const ReportByCategories = () => {
  const EnumTab = {
    pay: EnumTransactionType.pay,
    receive: EnumTransactionType.receive,
  };
  const [tab, setTab] = useState(EnumTab.pay);
  const [filterDate, setFilterDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const { filterDateType } = useSelector(transactionSelector);
  const [pieChartData, setPieChartData] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Thay đổi filter thời gian
  const handleChangeFilterTime = (e) => {
    dispatch(setFilterDateType(parseInt(e.target.value)));
  };

  const getChartData = async () => {
    const res = await getCategoriesReportService(filterDate, tab);
    if (res && res.success) {
      let lst = res.data;
      let pieData = [];
      if (lst && lst.length > 0) {
        lst.forEach((item) => {
          pieData.push({
            value: item.amount,
            name: item.category[0].description + ` (${item.percent}%)`,
          });
        });
      }

      setDetails(lst);
      setPieChartData(pieData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterDate) {
      setIsLoading(true);
      getChartData();
    }
  }, [filterDate, tab]);

  useEffect(() => {
    setFilterDate(commonFunction.getFilterDate(filterDateType));
  }, [filterDateType]);

  const handleDetailClick = (category) => {
    dispatch(setDetailCategory(category));
    navigate('/report/categories-detail');
  };

  return (
    <div id='report-revenue-expenditure'>
      <div className='frm-header p-3 justify-content-md-center'>
        <FaChevronLeft className='d-md-none' onClick={() => navigate(-1)} />
        <div className='frm-title'>Thu chi theo hạng mục</div>
      </div>
      <div className='d-flex justify-content-between ps-3 pe-3'>
        <div
          className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
            tab === EnumTab.pay ? 'active' : null
          }`}
          onClick={() => setTab(EnumTab.pay)}
        >
          Chi
        </div>
        <div
          className={`tab-control ps-3 pe-3 pt-2 pb-2 ${
            tab === EnumTab.receive ? 'active' : null
          }`}
          onClick={() => setTab(EnumTab.receive)}
        >
          Thu
        </div>
      </div>
      <div className='mt-2 mb-2 ps-3 pe-3 d-flex justify-content-between'>
        <div className='situation-header mb-1'>
          {filterDate
            ? commonFunction.getDDMMYYY(filterDate.startTime) +
              ' - ' +
              commonFunction.getDDMMYYY(filterDate.endTime)
            : null}
        </div>
        <select
          className='select-filter-date mb-1'
          defaultValue={filterDateType}
          onChange={handleChangeFilterTime}
          disabled={isLoading}
        >
          <option value={EnumFilterDateType.today}>Hôm nay</option>
          <option value={EnumFilterDateType.yesterday}>Hôm qua</option>
          <option value={EnumFilterDateType.week}>Tuần này</option>
          <option value={EnumFilterDateType.lastWeek}>Tuần trước</option>
          <option value={EnumFilterDateType.month}>Tháng này</option>
          <option value={EnumFilterDateType.lastMonth}>Tháng trước</option>
          <option value={EnumFilterDateType.quarter}>Quý này</option>
          <option value={EnumFilterDateType.lastQuarter}>Quý truớc</option>
          <option value={EnumFilterDateType.year}>Năm nay</option>
          <option value={EnumFilterDateType.lastYear}>Năm trước</option>
          <option value={EnumFilterDateType.jan}>Tháng 1</option>
          <option value={EnumFilterDateType.feb}>Tháng 2</option>
          <option value={EnumFilterDateType.mar}>Tháng 3</option>
          <option value={EnumFilterDateType.apr}>Tháng 4</option>
          <option value={EnumFilterDateType.may}>Tháng 5</option>
          <option value={EnumFilterDateType.jun}>Tháng 6</option>
          <option value={EnumFilterDateType.jul}>Tháng 7</option>
          <option value={EnumFilterDateType.aug}>Tháng 8</option>
          <option value={EnumFilterDateType.sep}>Tháng 9</option>
          <option value={EnumFilterDateType.oct}>Tháng 10</option>
          <option value={EnumFilterDateType.nov}>Tháng 11</option>
          <option value={EnumFilterDateType.dec}>Tháng 12</option>
        </select>
      </div>
      <div className='report-content p-3'>
        {isLoading ? (
          <BaseLoading />
        ) : (
          <>
            {pieChartData ? (
              <div className='loan-amount p-3 mb-3'>
                <PieChartCustom data={pieChartData} />
              </div>
            ) : null}
            {details && details.length > 0 ? (
              <div className='loan-amount p-3'>
                {details.map((item, i) => {
                  return (
                    <div
                      className='report-category-item pt-2 pb-2'
                      key={i}
                      onClick={() => handleDetailClick(item.category[0]._id)}
                    >
                      <div className='d-flex justify-content-between mb-2 mt-1'>
                        <div className='d-flex align-items-center'>
                          <img
                            src={item.category[0].image}
                            alt='icon'
                            width={28}
                            height={28}
                            className='me-2'
                          />
                          <span>{item.category[0].description}</span>
                        </div>
                        <div>
                          <span className='amount'>
                            {new Intl.NumberFormat('vi-VN', {
                              style: 'currency',
                              currency: 'VND',
                            }).format(item.amount)}
                          </span>
                          <span className='gray-icon'>
                            {' (' + item.percent + '%)'}
                          </span>
                        </div>
                      </div>
                      <div className='loan-bar mb-1'>
                        <div
                          className='loan-bar-done'
                          style={{
                            marginLeft: `${item.percent - 100}%`,
                            width: '100%',
                            backgroundColor: item.color,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportByCategories;
