import React, { useEffect, useState } from 'react';
import '../../css/view/budget/BudgetPage.css';
import { getAllBudgetService } from '../../services/budget.service';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { getAmountByBudget } from '../../services/transaction.service';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BaseLoading } from '../../components';
import { useDispatch } from 'react-redux';
import { setSelectedBudget } from '../../store/reducer/transactionSlice';

const BudgetPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [budgets, setBudgets] = useState(null);
  const [tab, setTab] = useState({});
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  /**
   * Lấy dữ liệu
   */
  const getData = async () => {
    const res = await getAllBudgetService();
    if (res.success) {
      if (res.data && res.data.length > 0) {
        const lst = [];
        res.data.forEach((item) => {
          let exist = lst.find(
            (x) => x.month === item.month && x.year === item.year
          );
          if (exist) {
            exist.budgets.push(item);
          } else {
            lst.push({
              month: item.month,
              year: item.year,
              budgets: [item],
            });
          }
        });
        setBudgets(lst);
        setTab(lst[0]);
      }
    }
  };

  /**
   * Lấy dữ liệu đã sử dụng
   * @param {*} activeTab
   */
  const getUsage = async (activeTab) => {
    setIsLoading(true);
    if (activeTab.budgets && activeTab.budgets.length > 0) {
      const tempItems = [];
      let totalAmount = 0,
        totalUseAmount = 0,
        today = new Date(),
        lastDay = new Date();
      for (let i = 0; i < activeTab.budgets.length; i++) {
        const x = activeTab.budgets[i];
        const res = await getAmountByBudget(x._id);
        x.useAmount = res.data;
        tempItems.push(x);
        totalAmount += x.amount;
        totalUseAmount += x.useAmount;
        lastDay = new Date(x.toDate);
      }
      setItems(tempItems);
      setTotal({
        totalAmount,
        totalUseAmount,
        totalDay: Math.ceil(Math.abs(lastDay - today) / (1000 * 60 * 60 * 24)),
      });
    }
    setIsLoading(false);
  };

  const handleClickBudget = (id) => {
    dispatch(setSelectedBudget(id));
    navigate('/budget/detail');
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {
    getUsage(tab);
  }, [tab]);

  return (
    <div id='budget-page'>
      <div className='frm-header p-3 justify-content-center'>
        <div className='frm-title'>Theo dõi ngân sách</div>
      </div>
      {isLoading ? (
        <BaseLoading />
      ) : (
        <div className='debt-content d-flex flex-column ps-3 pe-3'>
          {budgets && budgets.length > 0 ? (
            <div className='d-flex flex-column overflow-hidden flex-grow-1'>
              <div className='tab-list d-block overflow-auto text-nowrap'>
                {budgets && budgets.length > 0
                  ? budgets.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className={`d-inline tab-control ps-3 pe-3 pt-2 pb-2 ${
                            tab.month === item.month && tab.year === item.year
                              ? 'active'
                              : null
                          } `}
                          onClick={() => setTab(item)}
                        >
                          Tháng {item.month}
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className='flex-grow-1 overflow-auto'>
                {total ? (
                  <div className='loan-amount mt-3 mb-3 p-3 d-flex'>
                    <div className='col-8 pe-2'>
                      <div className='d-flex justify-content-between'>
                        <span className='amount'>Có thể chi</span>
                        <span
                          className={`${
                            total.totalAmount - total.totalUseAmount > 0
                              ? 'receive-amount'
                              : 'amount'
                          }`}
                          style={{ fontSize: 18 }}
                        >
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(total.totalAmount - total.totalUseAmount)}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span className='trans-desc'>
                          <i>Tổng ngân sách</i>
                        </span>
                        <span className='amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(total.totalAmount)}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span className='trans-desc'>
                          <i>Tổng đã chi</i>
                        </span>
                        <span className='pay-amount'>
                          {new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                          }).format(total.totalUseAmount)}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span className='trans-desc'>
                          <i>Số ngày còn lại</i>
                        </span>
                        <span>{total.totalDay} ngày</span>
                      </div>
                    </div>
                    <div className='col-4 ps-2'>
                      <CircularProgressbar
                        value={(total.totalUseAmount / total.totalAmount) * 100}
                        circleRatio={1}
                        styles={{
                          path: {
                            stroke: `${
                              total.totalUseAmount > total.totalAmount
                                ? '#ff4d6d'
                                : '#02c39a'
                            }`,
                          },
                          trailColor: '#ccc',
                          color: `${
                            total.totalUseAmount > total.totalAmount
                              ? '#ff4d6d'
                              : '#02c39a'
                          }`,
                        }}
                        text={`${Math.round(
                          (total.totalUseAmount / total.totalAmount) * 100
                        )} %`}
                      />
                    </div>
                  </div>
                ) : null}

                {items && items.length > 0
                  ? items.map((x) => {
                      return (
                        <div
                          className='loan-amount mt-3 mb-3 p-3'
                          key={x._id}
                          onClick={() => handleClickBudget(x._id)}
                        >
                          <div className='d-flex justify-content-between mb-2'>
                            <div className='d-flex flex-column'>
                              <span className='category d-flex align-items-center'>
                                <img
                                  src={x.categoryID.image}
                                  alt='Icon'
                                  width={36}
                                  height={36}
                                />
                                <div className='ms-2 d-flex flex-column'>
                                  <span className='amount'>
                                    {x.categoryID.description}
                                  </span>
                                  <span className='trans-desc'>
                                    <i>
                                      Đã chi{' '}
                                      {new Intl.NumberFormat('vi-VN', {
                                        style: 'currency',
                                        currency: 'VND',
                                      }).format(x.useAmount)}
                                    </i>
                                  </span>
                                </div>
                              </span>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                              <span className='amount'>
                                {new Intl.NumberFormat('vi-VN', {
                                  style: 'currency',
                                  currency: 'VND',
                                }).format(x.amount)}
                              </span>
                              <span className='trans-desc'>
                                <i>
                                  {x.amount - x.useAmount > 0
                                    ? 'Còn lại '
                                    : 'Bội chi '}
                                  {new Intl.NumberFormat('vi-VN', {
                                    style: 'currency',
                                    currency: 'VND',
                                  }).format(Math.abs(x.amount - x.useAmount))}
                                </i>
                              </span>
                            </div>
                          </div>
                          <div className='loan-bar'>
                            <div
                              className={`loan-bar-done ${
                                x.amount - x.useAmount > 0 ? '' : 'loan-bar-err'
                              }`}
                              style={{
                                width: `${
                                  x && x.amount
                                    ? (x.useAmount / x.amount) * 100
                                    : 0
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          ) : (
            <div className='no-loan d-flex align-items-center justify-content-center'>
              Chưa có ngân sách nào
            </div>
          )}
        </div>
      )}
      <button
        className='btn-add'
        onClick={() => {
          navigate('/budget/add');
        }}
      >
        <FaPlus />
      </button>
    </div>
  );
};

export default BudgetPage;
