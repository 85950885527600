import React from 'react';
import '../../css/layout/Sidebar.css';
import { NavLink } from 'react-router-dom';
import {
  FaBookOpen,
  FaChartLine,
  FaGear,
  FaHouse,
  FaMoneyCheckDollar,
  FaRightFromBracket,
  FaWindowRestore,
} from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import constants from '../../config/constants';
import { logout } from '../../store/reducer/authSlice';

const Sidebar = () => {
  const dispatch = useDispatch();
  // Đăng xuất
  const handleLogOut = () => {
    localStorage.removeItem(constants.TOKEN_NAME);
    dispatch(logout());
  };

  return (
    <div
      id='sidebar'
      className='p-3 d-flex flex-column justify-content-between'
    >
      <div className='sidebar-top'>
        <div className='sidebar-header  mt-3 mb-4'>
          <NavLink to='/' className='d-flex align-items-center'>
            <img src='/images/logo.png' alt='logo' width={32} height={32} />
            <span className='ms-3'>Money App</span>
          </NavLink>
        </div>
        <div className='sidebar-link d-flex flex-column'>
          <NavLink to='/' className='side-link p-2 mb-2'>
            <FaHouse />
            <span className='ms-2'>Tổng quan</span>
          </NavLink>
          <NavLink to='/transaction/detail' className='side-link p-2 mb-2'>
            <FaBookOpen />
            <span className='ms-2'>Giao dịch</span>
          </NavLink>
          <NavLink to='/debt' className='side-link p-2 mb-2'>
            <FaMoneyCheckDollar />
            <span className='ms-2'>Vay nợ</span>
          </NavLink>
          <NavLink to='/budget' className='side-link p-2 mb-2'>
            <FaWindowRestore />
            <span className='ms-2'>Ngân sách</span>
          </NavLink>
          <NavLink to='/report' className='side-link p-2 mb-2'>
            <FaChartLine />
            <span className='ms-2'>Báo cáo</span>
          </NavLink>
        </div>
      </div>
      <div className='sidebar-bot'>
        <div className='side-link side-link p-2 mb-2'>
          <FaGear />
          <span className='ms-2'>Thiết lập</span>
        </div>
        <div className='side-link side-link p-2' onClick={handleLogOut}>
          <FaRightFromBracket />
          <span className='ms-2'>Đăng xuất</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
