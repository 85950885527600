import axios from 'axios';
import constants from '../config/constants';

export const getGeneralReportService = async (filterDate) => {
  try {
    if (filterDate) {
      const res = await axios.post(
        `${constants.API_URL}/api/${constants.API_VER}/ma/report`,
        { filterDate: JSON.stringify(filterDate) }
      );

      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSurplusService = async () => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/surplus`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRevenueExpenditureService = async (year) => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/revenue-and-expenditure?year=${year}`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSpendingService = async (time) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/spending`,
      { filterDate: JSON.stringify(time) }
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRevenueService = async (time) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/revenue`,
      { filterDate: JSON.stringify(time) }
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDebtReportService = async () => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/debt`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCategoriesReportService = async (time, type) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/categories`,
      { filterDate: JSON.stringify(time), type: type }
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentFinancesService = async () => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/finances`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDebtDetailService = async (filter) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/debt-detail`,
      filter
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRevenueExpenditureServiceDetail = async (time) => {
  try {
    const res = await axios.post(
      `${constants.API_URL}/api/${constants.API_VER}/ma/report/revenue-and-expenditure-detail`,
      { filterDate: JSON.stringify(time) }
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
