import { useDispatch, useSelector } from 'react-redux';
import { authSelector, logout } from '../../store/reducer/authSlice';
import '../../css/view/me/MePage.css';
import constants from '../../config/constants';

const MePage = () => {
  const { user } = useSelector(authSelector);

  const dispatch = useDispatch();
  // Đăng xuất
  const handleLogOut = () => {
    localStorage.removeItem(constants.TOKEN_NAME);
    dispatch(logout());
  };

  return (
    <div id='debt-page'>
      <div className='frm-header p-3 justify-content-center'>
        <div className='frm-title'>Menu</div>
      </div>
      <div className='debt-content d-flex flex-column justify-content-between ps-3 pe-3'>
        <div className='top-button'>
          <div className='user-info p-3'>
            <div>{user.fullName}</div>
            <div>{user.username}</div>
          </div>
        </div>
        <div className='bottom-button'>
          <button className='logout-button' onClick={handleLogOut}>
            ĐĂNG XUẤT
          </button>
        </div>
      </div>
    </div>
  );
};

export default MePage;
