import React, { useEffect, useState } from 'react';
import '../../css/view/home/AddTransactionPage.css';
import {
  FaBars,
  FaCalendarAlt,
  FaCheck,
  FaChevronLeft,
  FaQuestion,
} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput from 'react-currency-input-field';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTransaction,
  deleteTransaction,
  editTransaction,
  getCategory,
  transactionSelector,
} from '../../store/reducer/transactionSlice';
import { EnumEditMode, EnumTransactionType } from '../../config';
import { SelectCategory, SelectDescription } from '../../components';
import { useNavigate } from 'react-router-dom';
import {
  addTransactionService,
  deleteTransactionService,
  editTransactionService,
} from '../../services/transaction.service';

const AddTransactionPage = () => {
  // Selector
  const {
    transactionEditMode,
    selectedTransaction,
    lstCategory,
    transactionType,
  } = useSelector(transactionSelector);
  // Thông tin giao dịch
  const [transaction, setTransaction] = useState(
    transactionEditMode === EnumEditMode.add && !selectedTransaction
      ? {
          amount: 0,
          categoryID: '',
          description: '',
          refDate: new Date(),
          transactionType: EnumTransactionType.pay,
        }
      : {
          _id: selectedTransaction._id,
          amount: selectedTransaction.amount,
          categoryID: '',
          description: selectedTransaction.description,
          refDate: new Date(selectedTransaction.refDate),
          transactionType: selectedTransaction.transactionType,
        }
  );
  // Show form chọn hạng mục
  const [isShowCategoryForm, setIsShowCategoryForm] = useState(false);
  // Hạng mục được chọn
  const [selectedCategory, setSelectedCategory] = useState(
    transactionEditMode === EnumEditMode.add && !selectedTransaction
      ? null
      : lstCategory.find(
          (item) => item._id === selectedTransaction.categoryID._id
        )
  );
  // Loại hạng mục
  const [transType, setTransType] = useState(transactionType);
  // Show màn chọn mô tả
  const [isShowDescriptionForm, setIsShowDescriptionForm] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeTransType = (e) => {
    setTransType(parseInt(e.target.value));
    setTransaction((prev) => {
      return { ...prev, transactionType: parseInt(e.target.value) };
    });
  };

  // Validate dữ liệu
  const validateBeforeSave = () => {
    if (parseFloat(transaction.amount) <= 0) {
      alert('Số tiền phải lớn hơn 0');
      return false;
    }
    if (!selectedCategory) {
      alert('Hạng mục không được để trống');
      return false;
    }
    return true;
  };

  // Lưu giao dịch
  const handleSaveTransaction = async () => {
    if (validateBeforeSave()) {
      let newTrans = transaction;
      newTrans.categoryID = selectedCategory._id;
      let res;
      if (transactionEditMode === EnumEditMode.add) {
        res = await addTransactionService(newTrans);
      } else {
        res = await editTransactionService(newTrans);
      }
      if (res && res.success) {
        if (transactionEditMode === EnumEditMode.add) {
          dispatch(addTransaction(res.data));
        } else {
          dispatch(editTransaction(res.data));
        }
        navigate(-1);
      } else {
        alert(res.message);
      }
    }
  };

  // Xóa giao dịch
  const handleDeleteTransaction = async () => {
    const res = await deleteTransactionService(transaction._id);
    if (res && res.success) {
      dispatch(deleteTransaction(res.data._id));
      navigate(-1);
    }
  };

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  return (
    <div id='add-transaction' className='p-3'>
      <div className='frm-header mb-3'>
        <FaChevronLeft onClick={() => navigate(-1)} />
        <select
          className='select-type'
          onChange={onChangeTransType}
          defaultValue={transType}
        >
          <option value={EnumTransactionType.pay}>Chi tiền</option>
          <option value={EnumTransactionType.receive}>Thu tiền</option>
        </select>
        <FaCheck onClick={handleSaveTransaction} />
      </div>
      <div className='frm-trans-content'>
        <div className='input-gr d-flex flex-column w-100'>
          <div className='w-100 text-end'>Số tiền</div>
          <div className='frm-money-gr mb-3 d-flex align-items-center'>
            <CurrencyInput
              className={`frm-money ${
                transType === EnumTransactionType.receive ? 'receive' : null
              }`}
              id='input-example'
              name='input-name'
              defaultValue={transaction.amount}
              decimalsLimit={2}
              onValueChange={(value) =>
                setTransaction((prev) => {
                  return { ...prev, amount: value };
                })
              }
            />
            <span>₫</span>
          </div>
          <div className='add-group mb-3'>
            <div className='group-icon'>
              {selectedCategory ? (
                <img
                  src={selectedCategory.image}
                  width={48}
                  height={48}
                  className='rounded'
                  alt='icon'
                />
              ) : (
                <FaQuestion />
              )}
            </div>
            <button
              className={`group-item ${selectedCategory ? 'selected' : null}`}
              onClick={() => setIsShowCategoryForm(true)}
            >
              {selectedCategory
                ? selectedCategory.description
                : 'Chọn hạng mục'}
            </button>
          </div>
          <div className='add-group mb-3'>
            <div
              className='group-icon'
              onClick={() => setIsShowDescriptionForm(true)}
            >
              <FaBars />
            </div>
            <input
              className='group-item'
              type='text'
              value={transaction.description}
              onChange={(e) =>
                setTransaction((prev) => {
                  return { ...prev, description: e.target.value };
                })
              }
              placeholder='Diễn giải'
            />
          </div>
          <div className='add-group mb-3'>
            <div className='group-icon'>
              <FaCalendarAlt />
            </div>
            <DatePicker
              className='group-item'
              selected={transaction.refDate}
              dateFormat='dd/MM/yyyy'
              onChange={(date) =>
                setTransaction((prev) => {
                  return { ...prev, refDate: date };
                })
              }
            />
          </div>
          <div className='d-flex'>
            {transactionEditMode === EnumEditMode.edit ? (
              <button
                className='login-button delete-button me-2'
                onClick={handleDeleteTransaction}
              >
                XÓA
              </button>
            ) : null}
            <button className='login-button' onClick={handleSaveTransaction}>
              LƯU
            </button>
          </div>
        </div>
      </div>
      {isShowCategoryForm ? (
        <SelectCategory
          transactionType={transType}
          onBackClick={() => setIsShowCategoryForm(false)}
          onChangeCategory={(value) => {
            setSelectedCategory(value);
            setIsShowCategoryForm(false);
          }}
        />
      ) : null}
      {isShowDescriptionForm ? (
        <SelectDescription
          categoryID={selectedCategory ? selectedCategory._id : null}
          onBackClick={() => setIsShowDescriptionForm(false)}
          onSelect={(val) => {
            setTransaction((prev) => {
              return { ...prev, description: val };
            });
            setIsShowDescriptionForm(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default AddTransactionPage;
