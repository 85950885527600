import axios from 'axios';
import constants from '../config/constants';

export const getObjectService = async (filterDate) => {
  try {
    const res = await axios.get(
      `${constants.API_URL}/api/${constants.API_VER}/ma/object`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
